import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function HuntCard({ data }) {
    const navigate = useNavigate();

    useEffect(() => {

    }, [])

    return (
        <div className='w-full h-[270px] mb-4 bg-white rounded-md shadow-md shadow-black/20 flex flex-col' onClick={() => navigate("/hunt/" + data?._id)}>
            <img src={data?.coverImage} alt="" className="w-full rounded-t-md h-[200px] object-cover" />
            <div className="flex items-center flex-1 w-full h-full px-3">
                <div className="w-full">
                    <p className="truncate">{data?.title}</p>
                    <p className="text-xs truncate"><span className="pr-1 text-base font-bold text-navactive">₹</span>{data?.giveway}</p>
                </div>
            </div>
        </div>
    )
}
