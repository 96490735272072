import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function BrandCard({ details }) {
    let navigate = useNavigate();

    return (
        <div className="" onClick={() => navigate(`/brand/${details?.name.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${details.id}`)}>
            <div className="bg-[#e0e0e0] h-[100px] w-[100px] rounded-full flex items-center justify-center">
                <img src={details.imageUrl} loading="lazy" alt="profile-1" className="h-full w-full rounded-full object-cover mix-blend-multiply contrast-1" />
            </div>
            <div className="mt-1">
                <p className="text-black text-center font-medium text-xs">{details.name}</p>
            </div>
        </div>
    )
}
