import { ArrowLeft, CircleNotch, VideoCamera } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard'
import { db } from '../firebase';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore/lite';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { async } from '@firebase/util';
import Downloadapp from '../components/downloadapp';
import ProfileReviewthumbnailcard from '../components/cards/profilereviewthumbnailcard';

export default function Userprofile() {
    const [tab, settab] = useState(1);
    const { id } = useParams();
    const [user, setuser] = useState({});
    const [reviews, setreviews] = useState([])
    const [flicks, setflicks] = useState([])
    const [reviews_page, setreviews_page] = useState(0);
    const [flicks_page, setflicks_page] = useState(0);
    const [reviews_loading, setreviews_loading] = useState(true);
    const [flicks_loading, setflicks_loading] = useState(true);
    const [verifiedModal, setverifiedModal] = useState(false);
    const [clicked, setclicked] = useState(false);
    const [downloadapp, setdownloadapp] = useState(false)
    const navigate = useNavigate();
    useEffect(() => {

        async function getuser() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
            }

            let reqOptions = {
                url: `https://userapi-zscu3untuq-el.a.run.app/getUser/${id}`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setuser(res?.data?.user)
            }).catch((err) => console.log(err))
        }
        async function getuserreviews() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
            }

            let reqOptions = {
                url: `https://userapi-zscu3untuq-el.a.run.app/getUserReviews/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setreviews_page(reviews_page + 1)
                setreviews(res?.data?.data)
                setreviews_loading(false)
            }).catch((err) => console.log(err))
        }
        async function getuserflicks() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
            }

            let reqOptions = {
                url: `https://userapi-zscu3untuq-el.a.run.app/getUserFLicks/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setflicks_page(flicks_page + 1)
                setflicks(res?.data?.data)
                setflicks_loading(false)
            }).catch((err) => console.log(err))
        }
        getuser()
        getuserreviews()
        getuserflicks()
    }, [id])

    async function getmoreuserreviews() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
        }

        let reqOptions = {
            url: `https://userapi-zscu3untuq-el.a.run.app/getUserReviews/${id}/${reviews_page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res?.data?.data.length === 0) {
                return setreviews_loading(null)
            }
            setreviews_page(reviews_page + 1)
            setreviews([...reviews, ...res?.data?.data])
            setreviews_loading(false)
        }).catch((err) => console.log(err))
    }
    async function getmoreuserflicks() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
        }

        let reqOptions = {
            url: `https://userapi-zscu3untuq-el.a.run.app/getUserFlicks/${id}/${flicks_page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res?.data?.data.length === 0) {
                return setflicks_loading(null)
            }
            setflicks_page(flicks_page + 1)
            setflicks([...flicks, ...res?.data?.data])
            setflicks_loading(false)
        }).catch((err) => console.log(err))
    }

    async function onChangereviews(isVisible) {
        if (isVisible) {
            getmoreuserreviews()
        }
    }
    async function onChangeflicks(isVisible) {
        if (isVisible) {
            getmoreuserflicks()
        }
    }

    async function handleclickverified() {
        setclicked(true);
        setTimeout(() => {
            setclicked(false);
            setverifiedModal(true);
        }, 1000);
    }

    console.log(flicks)
    console.log(reviews)
    return (
        <>
            <Helmet>
                <title>{`${user?.name} - MyRevue`}</title>
                <link rel="canonical" href={`https://myrevue.app/profile/${user?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${user?.id}`} />
                <meta name="title" content={`${user?.name} - MyRevue`} />
                <meta name="description" content={user?.description} />
                <meta name="keywords" content={`${user?.name}`} />
                <meta property="og:url" content={`https://myrevue.app/profile/${user?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${user?.id}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${user?.name} - MyRevue`} />
                <meta property="og:description" content={`${user?.name}`} />
                <meta property="og:image" content={user?.images?.xs ?? user?.imageUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="myrevue.app" />
                <meta property="twitter:url" content={`https://myrevue.app/profile/${user?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${user?.id}`} />
                <meta name="twitter:title" content={`${user?.name} - MyRevue`} />
                <meta name="twitter:description" content={`${user?.name}, `} />
                <meta name="twitter:image" content={user?.images?.xs ?? user?.imageUrl} />
            </Helmet>


            {downloadapp === true && <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                <div
                    className="absolute w-full h-screen bg-black cursor-pointer bg-opacity-80"
                    onClick={() => setdownloadapp(false)}
                >
                </div>
                <div className="relative z-50 w-full">
                    <div className="absolute w-full p-5 -bottom-1">
                        <Downloadapp close={setdownloadapp} />
                    </div>
                </div>
            </div>}
            <header className="flex justify-between items-center h-[45px] px-[20px] bg-white headerShadow">
                <ArrowLeft size={22} onClick={() => navigate(-1)} />
                <p className="">{user?.tag}</p>
                <p className=""></p>
                {/* <List size={32} /> */}
            </header>
            <section className="">
                <>
                    {verifiedModal === true ? <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                        <div
                            className="absolute w-full h-full bg-black cursor-pointer bg-opacity-80"
                            onClick={() => setverifiedModal(false)}
                        >
                        </div>
                        <div className="relative z-50 w-full">
                            <div className="bg-white w-full px-5 py-8 z-[600]">
                                <p className="text-xl text-center">Verified Account</p>
                                <p className="pt-3 text-sm text-center">This account is eligible for brand collaboration on MyRevue</p>
                            </div>
                        </div>
                    </div> : null}
                </>

                <div className="">
                    <img src={user?.coverImage ?? "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FEarn%20Rewards.webp?alt=media&token=136b4a56-93fb-4a21-a90a-f3f63b10a321"} alt="cover_image" className="w-full aspect-[21/6] object-cover" />
                </div>
                <div className="flex w-full px-5 gap-x-2">
                    <div className="border-2 border-white rounded-full h-28 w-28 -mt-14">
                        <img src={user.imageUrl ?? "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fdefault_dp.png?alt=media&token=12a37164-51dc-48a8-9bf3-f008fa6a5113"} alt="profile_image" className="w-full h-full rounded-full" />
                    </div>
                    <div className="flex-1 mt-1">
                        <div className="flex items-center gap-3">
                            <p className="text-xl font-semibold">{user?.name}</p>
                            {user?.verified === true ?
                                <img src={require("../images/shuriken.svg").default} alt="" className={`h-5 w-5 ${clicked ? "rotate" : ""}`} onClick={() => handleclickverified()} />
                                : null}
                        </div>
                        <p className="text-sm">{user?.about}</p>
                    </div>
                </div>
            </section>
            <section className="grid grid-cols-4 divide-x-[1px] divide-[#12121240] px-5 pt-5">
                <div className="cursor-pointer select-none">
                    <p className="text-xl font-semibold text-center">{user?.reviews ?? 0}</p>
                    <p className="text-xs text-center">Reviews</p>
                </div>
                <div className="cursor-pointer select-none">
                    <p className="text-xl font-semibold text-center">{user?.flicks ?? 0}</p>
                    <p className="text-xs text-center">Flicks</p>
                </div>
                <div className="cursor-pointer select-none" onClick={() => setdownloadapp(true)}>
                    <p className="text-xl font-semibold text-center">{user?.followers ?? 0}</p>
                    <p className="text-xs text-center">Followers</p>
                </div>
                <div className="cursor-pointer select-none" onClick={() => setdownloadapp(true)}>
                    <p className="text-xl font-semibold text-center">{user.following === null ? 0 : user.following}</p>
                    <p className="text-xs text-center">Following</p>
                </div>
            </section>
            <section className="grid h-10 grid-cols-2 mt-4">
                <div className={`w-full h-full flex items-center justify-center border-b ${tab === 1 ? "border-navactive text-navactive" : "text-black"}`} onClick={() => settab(1)}>
                    <VideoCamera size={20} color="currentcolor" />
                </div>
                <div className={`w-full h-full flex items-center justify-center border-b ${tab === 2 ? "border-navactive text-navactive" : "text-black"}`} onClick={() => settab(2)}>
                    <img src={require("../images/reels.svg").default} alt="" className="w-6 h-5 invert" />

                </div>
            </section>
            <section className="p-2 pb-20">
                {tab === 1 && <div className="grid grid-cols-2 gap-3 mt-3 hidescroll">
                    {reviews_loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {reviews?.map((review, index) => (
                                <ProfileReviewthumbnailcard details={review} key={index} />
                            ))}
                            {reviews_loading !== null && <ReactVisibilitySensor onChange={onChangereviews} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>}
                {tab === 2 && <div className="grid grid-cols-2 gap-3 mt-3 hidescroll">
                    {flicks_loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {flicks?.map((review, index) => (
                                <ProfileReviewthumbnailcard details={review} key={index} />
                            ))}
                            {flicks_loading !== null && <ReactVisibilitySensor onChange={onChangeflicks} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>}
            </section>
        </>
    )
}
