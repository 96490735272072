import firebase, { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore/lite";

const firebaseConfig = {
    apiKey: "AIzaSyByseu9xYgJzhDX3qLrjnWcOGw_keL0qgg",
    authDomain: "revueapp-4eeed.firebaseapp.com",
    databaseURL: "https://revueapp-4eeed-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "revueapp-4eeed",
    storageBucket: "revueapp-4eeed.appspot.com",
    messagingSenderId: "383429322359",
    appId: "1:383429322359:web:43b27e345b05c9c50c0872",
    measurementId: "G-WTB2G2DCF4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app)
export { app, db, auth };
