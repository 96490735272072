import { Bell, MagnifyingGlass } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import BrandCard from '../components/cards/brandcard'
import CategoriesCard from '../components/cards/categoriescard'
import ProductCard from '../components/cards/productcard'
import ProfileCard from '../components/cards/profilecard'
import YoutubeCard from '../components/cards/youtubecard'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Helmet } from 'react-helmet'
export default function Home() {
    const [topData, settopData] = useState({});
    const location = useLocation()
    const navigate = useNavigate();
    const [adds, setadds] = useState([]);
    const [subflickstype, setsubflickstype] = useState([])
    useEffect(() => {
        fetchtop();
        window.gtag("event", "page_view", {
            page_path: location.pathname + location.search,
        });
        async function getstory() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getAppAds",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                sessionStorage.setItem('ads', JSON.stringify(res?.data?.data))
                setadds(res?.data?.data);
            }).catch((err) => console.log(err))
        }
        async function getflcikstype() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getFlicksType/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                let uniqueArr = res?.data?.data?.filter((obj, index, self) =>
                    index === self.findIndex((t) => (
                        t.type === obj.type
                    ))
                );
                uniqueArr.sort((a, b) => a.rank - b.rank);

                setsubflickstype(uniqueArr)

            }).catch((err) => console.log(err))
        }
        getflcikstype()
        getstory()
    }, [])

    async function fetchtop() {
        let top = {
            products: [],
            brands: [],
            creators: []
        }
        async function gettopbrands() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getTopBrand/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                top.brands = res?.data?.data
            }).catch((err) => console.log(err))
        }
        async function gettopproducts() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getTopProduct/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                top.products = res?.data?.data
            }).catch((err) => console.log(err))
        }
        async function gettopbcreators() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getTopUser/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                top.creators = res?.data?.data
            }).catch((err) => console.log(err))
        }

        Promise.all([gettopbrands(), gettopbcreators(), gettopproducts()]).then(() => {
            settopData(top);

        })

    }


    return (
        <>
            <Helmet >
                <title>MyRevue - India's First Short Video Review & Experience Platform</title>
                <link rel="canonical" href="https://myrevue.app" />
                <meta name="title" content="MyRevue - India's First Short Video Review &amp; Experience Platform"></meta>
                <meta name="description" content="MyRevue is a 60-second product discovery and review platform driven by consumers. MyRevue users tell brands about their product and service experiences."></meta>
                <meta name="keywords" content="Video Reviews, Video testimonials, reviews.io, yotpo, facebook reviews, amazon reviews, trustpilot reviews, MyRevue, Brand reviews, product reviews, upload flicks, make flicks on MyRevue" />
                <meta property="og:url" content="https://myrevue.app" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="MyRevue - India's First Short Video Review &amp; Experience Platform" />
                <meta property="og:description" content="MyRevue is a 60-second product discovery and review platform driven by consumers. MyRevue users tell brands about their product and service experiences."></meta>
                <meta property="og:image" content="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fhomepage.webp?alt=media&amp;token=f2817158-0d3f-4903-9745-ddcea3a195b7" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="myrevue.app" />
                <meta property="twitter:url" content="https://myrevue.app" />
                <meta name="twitter:title" content="MyRevue - India's First Short Video Review &amp; Experience Platform"></meta>
                <meta name="twitter:description" content="MyRevue is a 60-second product discovery and review platform driven by consumers. MyRevue users tell brands about their product and service experiences." />
                <meta name="twitter:image" content="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbrandadminhome%2Fhomepage.webp?alt=media&amp;token=f2817158-0d3f-4903-9745-ddcea3a195b7" />
            </Helmet>
            <header className="flex justify-between items-center h-[45px] px-[20px] bg-white headerShadow">
                <img src={require("../images/squareLogo.svg").default} alt="squarelogo" className='' />
                <img src={require("../images/textLogo.svg").default} alt="squarelogo" className='h-7 pl-5' />
                <div className="flex items-center gap-2">
                    <Bell size={22} onClick={() => navigate("/notifications")} />
                    <MagnifyingGlass size={22} color='black' onClick={() => navigate("/search")} />
                </div>
            </header>
            <section>
                <div className="w-full shadow-sm shadow-black">
                    <a href="https://play.google.com/store/apps/details?id=com.myrevue.app" className="">
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbanner%20mobile.png?alt=media&token=192f8565-62eb-4055-9134-dd32f12c7f3c" alt="header banner" className="w-full" />
                    </a>
                </div>
            </section>
            <section className='px-2 py-3'>
                <p className="text-xl font-semibold pl-[2px]">Stories</p>
                <div className="overflow-scroll w-full pt-2 hidescroll">
                    <div className="flex gap-2 pb-[2px] pl-[2px]">
                        {adds?.map((ad, index) => (
                            <div className="" key={index}>
                                <div className="w-24 aspect-[9/16] " onClick={() => navigate(`/stories/${ad._id}`)}>
                                    <img src={ad?.thumbnail} loading="lazy" alt="" className="h-full w-full rounded-lg shadow-sm shadow-black" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='px-2 pb-1'>
                <p className="text-xl font-semibold">Flicks</p>
                <div className="overflow-scroll w-full pt-2 hidescroll">
                    <div className="flex gap-2 pl-[1px]">
                        {subflickstype?.map((flicks, index) => (
                            <div className="" key={index}>
                                <div className={`w-[180px] rounded-md flex  relative`} onClick={() => navigate('/flicksbytag/' + flicks?.type.replaceAll(" ", "-"))}>
                                    <img src={flicks?.tagImage} alt="flicks" className="w-full h-auto rounded-md" />
                                    <p className="tracking-wide font-medium absolute h-full pl-5 w-1/3 p-2 text-center flex items-center text-white leading-tight">{flicks?.type}</p>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </section>
            <section className='px-2 py-1'>
                <p className="text-xl font-semibold">Creators</p>
                <div className="overflow-scroll w-full pt-1 hidescroll">
                    <div className="flex gap-1">
                        {topData?.creators?.map((reviewer, index) => (
                            <ProfileCard details={reviewer} key={index} />
                        ))}
                    </div>
                </div>
            </section>
            <section className='px-2 py-3'>
                <p className="text-xl font-semibold">Categories</p>
                <div className="overflow-scroll w-full pt-2 hidescroll">
                    <div className="flex gap-1">
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Fhealth.png?alt=media&token=b8e29024-52b4-4032-907d-5d1e7002e49c" link="subcategory/cat_r6PVQbHb7tav2VJgqujJDA" title="Health & Beauty" />
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Fmovies.png?alt=media&token=872b2c66-3e74-4521-805a-c427268a4c5b" link="subcategory/cat_kdik4uvKPxQPc1iG8Nmn4S" title="Movies" />
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Felectronics.png?alt=media&token=dfba2f79-a3b3-4db9-b53a-ce47e93530ed" link="subcategory/cat_1HFWNd5hxVhgFCwBhTfusp" title="Electronics" />
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Fstores.png?alt=media&token=e2a24170-e6cd-49c8-8e28-7ca1bb0d6429" link="subcategory/cat_4s46eoAJiZUn1cVg2tQ8La" title="Online Stores & Websites" />
                    </div>
                    <div className="flex gap-1 mt-1">
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Fshops.png?alt=media&token=8bb3d161-7c08-4cfd-86eb-e0ab630d13eb" link="subcategory/cat_vrYhTy8pexBVqLgf3Cx9JK" title="Stores & Shops" />
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Fautomobile.png?alt=media&token=f6997f52-3849-4ba9-a004-b784085f893f" link="subcategory/i-auB5-1" title="Automobile" />
                        <CategoriesCard image="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcategories_icon%2Fgroceries.png?alt=media&token=c09b7805-d88b-4005-97e4-c02d59566f8c" link="subcategory/Yk0kzq4W" title="Grocery & Gourmet Foods" />
                    </div>
                </div>
            </section>
            <section className='px-2'>
                <p className="text-xl font-semibold">Top Brands</p>
                <div className="overflow-scroll w-full pt-2 hidescroll">
                    <div className="flex gap-2">
                        {topData?.brands?.map((brand, index) => (
                            <BrandCard details={brand} key={index} />
                        ))}

                    </div>
                </div>
            </section>
            <section className='p-5 px-2'>
                <p className="text-xl font-semibold">Top Products</p>
                <div className="overflow-scroll w-full pt-2 hidescroll">
                    <div className="flex gap-2">
                        {topData?.products?.map((product, index) => (
                            <ProductCard details={product} key={index} />
                        ))}

                    </div>
                </div>
            </section>

            <section className='px-2'>
                <p className="text-xl font-semibold">Learn about App</p>
                <div className="w-full">
                    <YoutubeCard title="How To Post A Review On MyRevue - Product Discovery App" image="https://img.youtube.com/vi/o_WHZvfggyw/maxresdefault.jpg" url="https://youtu.be/o_WHZvfggyw" />
                    <YoutubeCard title="How To Post Flicks On MyRevue App - Product Discovery App" image="https://img.youtube.com/vi/LDhzK4Y_E3k/maxresdefault.jpg" url="https://youtu.be/LDhzK4Y_E3k" />
                    <YoutubeCard title="How To Post a Review of new Product On MyRevue - Product Discovery App" image="https://img.youtube.com/vi/t-yAgq7JU9o/maxresdefault.jpg" url="https://youtu.be/t-yAgq7JU9o" />
                    <YoutubeCard title="What is MyRevue App - Product Discovery App & Why to Post Flicks on the app" image="https://img.youtube.com/vi/TPxHdrOHfGk/maxresdefault.jpg" url="https://youtu.be/TPxHdrOHfGk" />
                </div>
            </section>
        </>
    )
}
