import axios from 'axios';
import { ArrowLeft, X } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
import HalfStarRating from '../components/halfstarrating';
export default function Search() {
    const [term, setterm] = useState('');
    const [tab, settab] = useState(1);
    const navigate = useNavigate();
    const [results, setresults] = useState([]);
    const [stories, setstories] = useState([]);
    const [subflicks, setsubflicks] = useState([])
    const { queries } = useParams();
    const tabs = ["brand", "product", "user"];
    const location = useLocation();
    useEffect(() => {
        if (queries) {
            setterm(queries);
            if (tab === 2) {
                axios.get(`https://mainapi2-zscu3untuq-el.a.run.app/search/${queries}`, { headers: { "Accept": "*/*", "Authorization": "alsdfhqp94LNSLKHFSDFDFartoq34u509rajlgakdng2" } }).then((res) => setresults(res?.data?.data)).catch((err) => console.log(err));
            }
            else {
                axios.get(`https://mainapi2-zscu3untuq-el.a.run.app/search/${tabs[tab - 1]}/${queries}`, { headers: { "Accept": "*/*", "Authorization": "alsdfhqp94LNSLKHFSDFDFartoq34u509rajlgakdng2" } }).then((res) => setresults(res?.data?.data)).catch((err) => console.log(err));
            }
        }
        if (!queries) {
            getflicks()
            getflcikstype()
        }
    }, [tab])

    async function getflicks() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
        }

        let reqOptions = {
            url: "https://userspecificapi-zscu3untuq-el.a.run.app/getStory/user",
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            setstories(res?.data?.data)
        }).catch((err) => console.log(err))
    }
    async function getflcikstype() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
        }

        let reqOptions = {
            url: "https://userspecificapi-zscu3untuq-el.a.run.app/getFlicksType/user",
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            setsubflicks(res?.data?.data)
        }).catch((err) => console.log(err))
    }

    function redirecttopage(title, id) {
        const nextto = tab === 1 ? "brand" : (tab === 2 ? "product" : "profile");
        navigate(`/${nextto}/${title.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${id}`)
    }

    async function changeroute(value) {
        sessionStorage.setItem("previousRoute", `${location.pathname}`)
        navigate(value)
        return
    }
    return (
        <>
            {!queries && results.length === 0 ?
                <section className='p-3 '>
                    <div className=" relative bg-[#eeeeee] shadow-sm  rounded-md w-full">
                        <div className="h-full flex items-center pl-2 absolute z-10 top-0 left-0">
                            <div className="h-8 w-8 ">
                                <button className="h-full w-full" onClick={() => navigate(-1)}>
                                    <ArrowLeft size={28} color="#000000" />
                                </button>
                            </div>
                        </div>
                        <form action={`/search/${term}`} className="w-full flex-1">
                            <input type="text" placeholder='Search products, brands, users' value={term} className=" px-12 h-12 w-full bg-transparent appearance-none outline-none " onChange={(event) => setterm(event.target.value)} />
                        </form>
                        <div className="h-full flex items-center pr-2 absolute z-10 top-0 right-0">
                            <div className="h-8 w-8 ">
                                <button className="h-full w-full" onClick={() => setterm("")}>
                                    <X size={28} color="#000000" />
                                </button>
                            </div>
                        </div>
                    </div>
                </section> :
                <section className=' border-b-[1px] border-[#80808040] px-5 py-3 pb-5'>
                    <div className=" relative bg-[#e8e8e8] shadow-sm  rounded-md w-full">
                        <div className="h-full flex items-center pl-2 absolute z-10 top-0 left-0">
                            <div className="h-8 w-8 ">
                                <button className="h-full w-full" onClick={() => navigate(-1)}>
                                    <ArrowLeft size={28} color="#000000" />
                                </button>
                            </div>
                        </div>
                        <form action={`/search/${term}`} className="w-full flex-1">
                            <input type="text" placeholder='Search products, brands, users' value={term} className=" px-12 h-12 w-full bg-transparent appearance-none outline-none " onChange={(event) => setterm(event.target.value)} />
                        </form>
                        <div className="h-full flex items-center pr-2 absolute z-10 top-0 right-0">
                            <div className="h-8 w-8 ">
                                <button className="h-full w-full" onClick={() => setterm("")}>
                                    <X size={28} color="#000000" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center justify-evenly mt-3">
                        <button className={`${tab === 1 ? "bg-navactive text-white" : "text-black "} text-sm px-7 py-[10px] rounded-md`} onClick={() => settab(1)}>Brands</button>
                        <button className={`${tab === 2 ? "bg-navactive text-white" : "text-black "} text-sm px-7 py-[10px] rounded-md`} onClick={() => settab(2)}>Products</button>
                        <button className={`${tab === 3 ? "bg-navactive text-white" : "text-black "} text-sm px-7 py-[10px] rounded-md`} onClick={() => settab(3)}>Users</button>
                    </div>
                </section>}

            <section className="p-3">
                {results?.map((result, index) => (
                    <div className="flex w-full gap-3 hover:bg-white p-2 rounded items-center" key={index} onClick={() => redirecttopage(result.name, result.id)} >
                        <div className="h-10 w-10 rounded-full bg-black flex items-center justify-center">
                            {result?.imageUrl ? <img src={result.imageUrl} alt="" className="h-full w-full rounded-full" />
                                :
                                <p className="text-lg text-white">{result.name.slice(0, 2).toUpperCase()}</p>
                            }
                        </div>
                        <div className="space-y-1">
                            <div className="flex items-center gap-2">
                                <p className="text-sm">{result.name}</p>
                                {result?.verified === true ?
                                    <img src={require("../images/shuriken.svg").default} alt="" className={`h-5 w-5`} />
                                    : null}
                            </div>
                            {result?.id?.includes("pro") ? <>
                                <HalfStarRating colorcode="#FFC300" rating={result.rating} sizeh="22" />
                                <p className="text-xs">{result.reviews} reviews</p>
                            </>
                                : null}
                        </div>
                    </div>
                ))}
                {queries && results.length === 0 ?
                    <div className="">
                        <p className="text-center text-2xl">No results found
                            <br /><span className="font-semibold">“{queries}“</span>
                        </p>
                        <p className="mt-5 text-sm text-center">Check your Spelling & Extra Spaces.</p>
                        <img src={require("../images/SearchnoFound.svg").default} alt="svgsearch" className="w-2/3 mx-auto mt-8" />
                    </div>
                    : null}

                {!queries && results.length === 0 ?
                    <div className="">
                        <div className="">
                            <p className="font-semibold text-xl text-center">
                                Ideas from Creators
                            </p>
                            <div className="overflow-scroll w-full pt-2 hidescroll">
                                <div className="flex gap-1 pb-3 pl-[3px]">
                                    {stories?.map((ad, index) => (
                                        <div className="relative" key={index} onClick={() => changeroute(`/viewReview/flicks/${ad?.id}`)}>
                                            <div className="absolute h-full w-full flex flex-col items-center justify-end pb-2 bg-gradient-to-t from-[#00000080] to-transparent overflow-hidden rounded-xl shadow-sm shadow-black">
                                                <div className="h-10 w-10 rounded-full">
                                                    <img src={ad?.user?.imageUrl} alt="" className="h-full w-full object-cover rounded-full" />
                                                </div>
                                                <p className="text-center text-white font-medium text-[10px] tracking-wide ">{ad?.user?.name}</p>
                                            </div>
                                            <div className="w-28 aspect-[9/16]" >
                                                <img src={ad?.thumbnail} loading="lazy" alt="" className="h-full w-full rounded-lg " />
                                            </div>

                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4">
                            <p className="font-semibold text-xl text-center">
                                Ideas for you
                            </p>
                            <div className="grid grid-cols-2 gap-1 mt-3">
                                {subflicks?.map((flicktype, index) => (
                                    <div className="w-full h-20 rounded-lg relative shadow-sm shadow-black" key={index} onClick={() => navigate("/flickssearch/" + flicktype?.name.replaceAll(" ", "-"))}>
                                        <div className="h-20 w-full bg-[#00000070] absolute  rounded-lg flex items-center justify-center">
                                            <p className="text-white font-medium tracking-wide text-center">{flicktype?.name}</p>
                                        </div>
                                        <div className="h-full w-full">
                                            <img src={flicktype?.imageUrl} alt="" className="h-full w-full rounded-lg object-cover" />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    : null}

            </section>
        </>

    )
}
