import { PaperPlaneRight } from 'phosphor-react'
import React from 'react'

export default function YoutubeCard({ url, title, image }) {
    return (
        <a target="_blank" href={url} rel="noreferrer">

            <div className="w-full my-4" >
                <img src={image} alt="about-1" className="w-full rounded-xl" />
                <div className="flex gap-2 mt-1 items-center ">
                    <div className="pl-2 ">
                        <PaperPlaneRight size={28} color="#000000" weight="fill" />
                        {/* <img src={require("../../images/squareLogo.svg").default} alt="squarelogo" className='transform scale-125 mt-1' /> */}
                    </div>
                    <div className="">
                        <p className="font-semibold leading-tight">{title}</p>
                    </div>
                </div>
            </div>
        </a>
    )
}
