import { BrowserRouter, Route, Routes, } from "react-router-dom";
import React from "react";
import Home from "./pages/home";
import Layout from "./pages/layout";
import NoPage from "./pages/noPage";
import Subcategories from "./pages/subcategories";
import Brand from "./pages/brand";
import Product from "./pages/product";
import "./App.css"
import Subcategory from "./pages/subcategory";
import Profile from "./pages/profile";
import Anypage from "./pages/anypage";
import Timeline from "./pages/timeline";
import Userprofile from "./pages/userprofile";
import Viewreview from "./pages/viewreview";
import Search from "./pages/search";
import Myaccountnav from "./pages/myaccountnav";
import Feedback from "./pages/feedback";
import Stories from "./pages/stories";
import Flickssearch from "./pages/flickssearch";
import Notifications from "./pages/notifications";
import Flicksbytag from "./pages/flickstag";
import Hunt from "./pages/hunt";
import Hunts from "./pages/hunts";
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/hunts" element={<Hunts />} />
          <Route path="/hunt/:id" element={<Hunt />} />
          <Route path="/subcategory/:id" element={<Subcategories />} />
          <Route path="/category/:cat/:subcat/:id" element={<Subcategory />} />
          <Route path="/brand/:name/:id" element={<Brand />} />
          <Route path="/product/:name/:id" element={<Product />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/:name/:id" element={<Userprofile />} />
          <Route path="/viewReview/:type/:id" element={<Viewreview />} />
          <Route path="/search/:queries" element={<Search />} />
          <Route path="/search" element={<Search />} />
          <Route path="/stories/:id" element={<Stories />} />
          <Route path="/flicksbytag/:tag" element={<Flicksbytag />} />
          <Route path="/flickssearch/:sentence" element={<Flickssearch />} />
          <Route path="/reviews" element={<Anypage />} />
          <Route path="/timeline" element={<Timeline />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/myaccount" element={<Myaccountnav />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}
