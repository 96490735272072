import React from 'react'
import { Star } from "phosphor-react"
export default function Ratingcomponent({ rating }) {

    return (
        <div>
            {((rating >= 0) && (rating < 1)) ? <div className="flex gap-x-1">
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
            </div> : null}
            {((rating >= 1) && (rating < 2)) ? <div className="flex gap-x-1">
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
            </div> : null}
            {((rating >= 2) && (rating < 3)) ? <div className="flex gap-x-1">
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
            </div> : null}
            {((rating >= 3) && (rating < 4)) ? <div className="flex gap-x-1">
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
            </div> : null}
            {((rating >= 4) && (rating < 5)) ? <div className="flex gap-x-1">
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " color="#FFC300" />
            </div> : null}
            {((rating === 5) && (rating > 5)) ? <div className="flex gap-x-1">
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
                <Star className="h-6 w-6  " weight="fill" color="#FFC300" />
            </div> : null}
        </div>
    )
}