import axios from 'axios';
import { ArrowLeft, CircleNotch, LinkSimple, ShareNetwork, VideoCamera } from 'phosphor-react';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard';
import Downloadapp from '../components/downloadapp';
import HalfStarRating from '../components/halfstarrating';

export default function Product() {
    const navigate = useNavigate();
    const { name, id } = useParams();
    const [readmore, setreadmore] = useState(false);
    const [product, setproduct] = useState({});
    const [attributes, setattributes] = useState([]);
    const [topreviews, settopreviews] = useState([])
    const [reviews, setreviews] = useState([]);
    const [downloadapp, setdownloadapp] = useState(false);
    const [page, setpage] = useState(0);
    const [loading, setloading] = useState(true);
    const location = useLocation()
    useEffect(() => {
        async function getProduct() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
            }

            let reqOptions = {
                url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${id}`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                if (JSON.stringify(res?.data?.product?.attributes) === "{}") {
                    setproduct({ ...res?.data?.product, avgrating: res?.data?.product?.rating });
                    return
                }
                else {
                    const arr = Object.values(res?.data?.product?.attributes ?? {})
                    const nums = arr.filter(n => !isNaN(n)).map(Number)
                    const ave = nums.reduce((x, y) => x + y) / (nums.length || 1);
                    setproduct({ ...res?.data?.product, avgrating: ave });
                    setattributes(Object.keys(res?.data?.product?.attributes ?? {}))
                }

            }).catch((err) => console.log(err))
        }
        async function getReviewsProduct() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
            }

            let reqOptions = {
                url: `https://productapi-zscu3untuq-el.a.run.app/getReviewsByProduct/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                const reviews = res?.data?.data;
                reviews.map((rev) => rev.type = rev.flickTag ? "flicks" : "review")
                const sorted = reviews.sort((a, b) => a.views > b.views);
                setreviews(reviews);
                settopreviews(sorted.slice(0, 10))
                setpage(page + 1)
                setloading(false)
            }).catch((err) => console.log(err))
        }
        getProduct()
        getReviewsProduct()
    }, [])

    const handleShareButton = () => {
        // Check if navigator.share is supported by the browser
        if (navigator.share) {
            navigator
                .share({
                    url: `https://myrevue.app/product/${product?.name.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${product.id}`
                })

        } else {
        }
    };

    async function getmorereviews() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
        }

        let reqOptions = {
            url: `https://productapi-zscu3untuq-el.a.run.app/getReviewsByProduct/${id}/${page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res.data?.data.length === 0) {
                setloading(null)
            }
            setpage(page + 1)
            setreviews([...reviews, ...res?.data?.data])
        }).catch((err) => console.log(err))
    }
    function onChange(isVisible) {

        if (isVisible) {
            getmorereviews()
        }
    }

    async function changeroute(value) {
        sessionStorage.setItem("previousRoute", `${location.pathname}`)
        navigate(value)
    }
    return (
        <>
            <Helmet>
                <title>{`${product?.name} - MyRevue`}</title>
                <link rel="canonical" href={`https://myrevue.app/product/${product?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${product?.id}`} />
                <meta name="title" content={`${product?.name} - MyRevue`} />
                <meta name="description" content={product?.description} />
                <meta name="keywords" content={`${product?.name}, ${product?.parentName}, MyRevue, MyRevue Products, MyRevue - ${product?.subcategory}`} />
                <meta property="og:url" content={`https://myrevue.app/product/${product?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${product?.id}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${product?.name} - MyRevue`} />
                <meta property="og:description" content={`${product?.name}, ${product?.parentName}, MyRevue, MyRevue Products, MyRevue - ${product?.subcategory}`} />
                <meta property="og:image" content={product?.images?.xs ?? product?.imageUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="myrevue.app" />
                <meta property="twitter:url" content={`https://myrevue.app/product/${product?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${product?.id}`} />
                <meta name="twitter:title" content={`${product?.name} - MyRevue`} />
                <meta name="twitter:description" content={`${product?.name}, ${product?.parentName}, MyRevue, MyRevue Products, MyRevue - ${product?.subcategory}`} />
                <meta name="twitter:image" content={product?.images?.xs ?? product?.imageUrl} />
            </Helmet>
            {downloadapp === true && <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                <div
                    className="absolute w-full h-screen bg-black cursor-pointer  bg-opacity-80"
                    onClick={() => setdownloadapp(false)}
                >
                </div>
                <div className="relative z-50 w-full">
                    <div className="absolute w-full p-5 -bottom-1">
                        <Downloadapp close={setdownloadapp} />
                    </div>
                </div>
            </div>}
            <header className='flex items-center justify-between h-[45px] bg-white px-5 shadow-md'>
                <div className="w-6 h-6 " onClick={() => changeroute(-1)}>
                    <ArrowLeft size={22} color="black" />
                </div>
                <div className="text-center text-black">{product?.name}</div>
                <div className=""></div>
            </header>
            <section className='w-full mt-2'>
                <div className="w-full aspect-[5/3] bg-black  relative">
                    <div className="absolute bg-[#000000b7] h-full w-full flex items-center justify-center">
                        <p className="text-2xl font-medium text-center text-white">{product?.name}</p>
                    </div>
                    <div className="absolute flex justify-center w-full -bottom-5">
                        <div className="flex items-center gap-5 px-3 bg-white rounded-md shadow-md h-11 w-fit shadow-black">
                            {product?.affiliateLink ? <a href={product?.affiliateLink} target="_blank" rel="noopener noreferrer">
                                <button className='flex flex-col items-center text-[#F92147]'>
                                    <LinkSimple size={20} color="currentcolor" />
                                    <p className="text-xs">Buy Now</p>
                                </button>
                            </a>
                                : null}
                            <button className='flex flex-col items-center text-[#F92147]' onClick={() => setdownloadapp(true)}>
                                <img src={require("../images/reelspink.svg").default} alt="" className="w-5 h-5 " />
                                <p className="text-xs">Flicks</p>
                            </button>
                            <button className='flex flex-col items-center text-[#F92147]' onClick={() => setdownloadapp(true)}>
                                <VideoCamera size={20} color="currentcolor" />
                                <p className="text-xs">Add Review</p>
                            </button>
                            <button className='flex flex-col items-center text-[#F92147]' onClick={handleShareButton}>
                                <ShareNetwork size={20} weight="fill" color="currentcolor" />
                                <p className="text-xs">Share</p>
                            </button>
                        </div>
                    </div>
                    <img src={product?.imageUrl} alt="product_image" className="object-cover w-full h-full" />
                </div>
            </section>
            <section className='p-5 mt-5'>
                <div className="flex flex-col items-center space-y-2">
                    <p className="text-sm tracking-wide text-center">
                        Overall Rating:
                    </p>
                    <HalfStarRating rating={product?.avgrating} sizeh={24} colorcode="#FFC300" />
                </div>
            </section>
            <section className='px-2'>
                <div className="grid grid-cols-2 gap-4">
                    {attributes.map((att, index) => (
                        <div className="flex flex-col items-center justify-center space-y-[2px] " key={index}>
                            <p className="text-xs">{att}</p>
                            <HalfStarRating rating={isNaN(product?.attributes?.[att]) ? 0 : product?.attributes?.[att]} sizeh={20} colorcode="#6E41E2" />
                        </div>
                    ))}
                </div>
                <div className="my-3">
                    <p className={`text-xs ${readmore === true ? "" : "line-clamp-4"}`}>
                        {product.description}
                    </p>
                    <div className="flex items-center justify-between my-4 ">
                        <p className="text-navactive hover:bg-navactive pl-1 text-sm pr-4 py-[8px] hover:bg-opacity-30  tracking-wide rounded" onClick={() => setreadmore(!readmore)}>{readmore === false ? "Read More" : "Read Less"}</p>
                        {/* <button className="bg-navactive text-white px-5 py-[8px] text-xs tracking-wide rounded flex gap-x-2 items-center" onClick={() => setsave(!save)}>
                            {save === false ? <BookmarkSimple size={24} />
                                :
                                <BookmarkSimple size={24} weight="fill" />
                            }
                            {save === false ?
                                <p className="">Save for later</p>
                                :
                                <p className="">Saved</p>
                            }
                        </button> */}
                    </div>
                </div>
            </section>
            <section className='px-2 mt-5'>
                <p className="text-xl font-semibold">Top Reviews</p>
                <div className="w-full pt-5 overflow-x-scroll hidescroll">
                    <div className="flex gap-2">
                        {topreviews?.map((review, index) => (
                            <div key={index} className="h-[200px] aspect-[9/16] bg-black rounded-md relative" onClick={() => { changeroute(`/viewReview/${review?.type}/${review.id}`); sessionStorage.setItem("reviewsSortKind", "topreviews") }}>
                                <div className="absolute flex flex-col justify-end w-full h-full rounded-md bg-gradient-to-b from-transparent via-transparent to-black">
                                    <div className="flex justify-between w-full gap-2 p-3">
                                        <div className="flex items-center justify-center w-8 h-8 bg-white rounded-full">
                                            {review?.product?.imageUrl !== null ? <img src={review?.product?.imageUrl} alt="" className="w-full h-full rounded-full " />
                                                :
                                                <div className="text-xl">{review?.product.name.slice(0, 2).toUpperCase()}</div>
                                            }
                                        </div>
                                        <div className="w-[calc(100%-48px)] flex-1">
                                            <p className="text-xs font-light text-white truncate">{review?.product?.name}</p>
                                            <p className="text-xs tracking-wide text-white truncate font-extralight">{review?.user?.name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full h-full ">
                                    <img src={review?.thumbnail} alt="review_image" className="object-cover w-full h-full rounded-md" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className='mt-3'>
                <div className="w-full shadow-sm shadow-black">
                    <a href="https://play.google.com/store/apps/details?id=com.myrevue.app" className="">
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbanner%20mobile.png?alt=media&token=192f8565-62eb-4055-9134-dd32f12c7f3c" alt="header banner" className="w-full" />
                    </a>
                </div>
            </section>
            <section className="p-2 pb-20">
                <div className="">
                    <p className="text-xl font-semibold">All Reviews</p>
                </div>
                <div className="grid grid-cols-2 gap-3 mt-3">
                    {loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {reviews?.map((review, index) => (
                                <Reviewthumbnailcard details={review} key={index} />
                            ))}
                            {loading !== null && <ReactVisibilitySensor onChange={onChange} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>
            </section>
        </>
    )
}
