import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ProductCard({ details }) {
    let navigate = useNavigate();

    return (
        <div className="relative" onClick={() => navigate(`/product/${details?.name.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${details.id}`)}>
            <div className=" h-[100px] w-[100px] rounded-xl">
                <img src={details.imageUrl} loading="lazy" alt="profile-1" className="h-full w-full rounded-xl object-cover mix-blend-multiply contrast-1" />
            </div>
            <div className="mt-1">
                <p className="text-black text-center font-medium text-xs">{details.name}</p>
            </div>
        </div>
    )
}
