import { collection, getDocs, limit, query, where } from 'firebase/firestore/lite';
import { ArrowLeft, CircleNotch } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import HalfStarRating from '../components/halfstarrating';
import { db } from '../firebase';
import axios from 'axios';
import ReactVisibilitySensor from 'react-visibility-sensor';

export default function Subcategory() {
    const navigate = useNavigate();
    const { cat, subcat, id } = useParams();
    const sub_cat = subcat.replaceAll("-", " ");
    const [products, setproducts] = useState([]);
    const [newproducts, setnewproducts] = useState([]);
    const [page, setpage] = useState(0)
    const [loading, setloading] = useState(true)
    useEffect(() => {
        setloading(true)
        getmoreproducts()
    }, [sub_cat, id])

    async function getmoreproducts() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
        }

        let reqOptions = {
            url: `https://productapi-zscu3untuq-el.a.run.app/getProductBysubcategory/${sub_cat}/${page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res?.data?.data.length === 0) {
                setloading(null)
            }
            setproducts([...products, ...res?.data?.data])
            setloading(false);
            setpage(page + 1)
        }).catch((err) => console.log(err))
    }


    async function onChange(isVisible) {
        if (isVisible) {
            getmoreproducts()
        }
    }

    return (
        <>
            <Helmet>
                <title>{`${sub_cat} - MyRevue`}</title>
                <link rel="canonical" href={`https://myrevue.app/category/${cat?.trim().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${sub_cat?.trim().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${id}`} />
                <meta name="title" content={`${sub_cat} - MyRevue`} />
                <meta property="og:url" content={`https://myrevue.app/category/${cat?.trim().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${sub_cat?.trim().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${id}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${cat} - MyRevue`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="myrevue.app" />
                <meta property="twitter:url" content={`https://myrevue.app/category/${cat?.trim().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${sub_cat?.trim().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${id}`} />
            </Helmet>
            <header className='flex items-center justify-between px-5 bg-white shadow-md h-14'>
                <div className="h-9 w-9 " onClick={() => navigate(-1)}>
                    <ArrowLeft size={32} color="black" />
                </div>
                <div className="text-black">{sub_cat}</div>
                <div className=""></div>
            </header>
            <section className='px-2 py-5'>
                <div className="grid w-full grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
                    {loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {products?.map((product, index) => (
                                <div className="relative w-full rounded-lg aspect-square" key={index} onClick={() => navigate("/product/" + product?.name?.replaceAll(" ", "-") + "/" + product.id)}>
                                    <div className="absolute flex flex-col justify-between w-full h-full p-2 bg-black rounded-lg bg-opacity-70">
                                        <div className="">
                                            <p className="text-sm tracking-wide text-white">{product?.name}</p>
                                        </div>
                                        <div className="flex justify-between">
                                            <p className="text-xs text-white">{product?.reviews ?? 0} Reviews</p>
                                            <HalfStarRating rating={product?.rating} sizeh={13} colorcode="#FFC300" />
                                        </div>
                                    </div>
                                    <div className="w-full aspect-square">
                                        <img src={product?.imageUrl} loading="lazy" alt="product_image" className="object-cover w-full h-full rounded-lg" />
                                    </div>
                                </div>
                            ))}
                            {loading !== null && <ReactVisibilitySensor onChange={onChange} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>
            </section>
            <section className='pb-16'></section>
        </>
    )
}
