import axios from 'axios';
import { Eye, Heart, ShareNetwork, Star } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';

export default function Reviewvideo({ details }) {
    const [user, setuser] = useState(JSON.parse(sessionStorage.getItem('user')))
    const [visibility, setvisibility] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [progress, setProgress] = useState(0);
    const [showDescription, setshowDescription] = useState(false);
    const vidRef = useRef();
    const viewRef = useRef(false)


    function onChange(isVisible) {

        if (isVisible) {
            setvisibility(true);
            vidRef.current.play();
            if (location.pathname.includes('viewReview')) {
                navigate(`/viewReview/${details?.type}/${details?.id}`, { replace: true })
            }
        }
        else {
            setvisibility(false)
            vidRef.current.pause();
        }
    }

    const handleOnTimeUpdate = () => {
        const progress = (vidRef.current.currentTime / vidRef.current.duration) * 100;
        setProgress(isNaN(Math.trunc(progress)) ? 0 : Math.trunc(progress));
        const duration = vidRef.current.currentTime.toFixed(0);
        // if (duration > 5) {
        //     if (viewRef.current === false) {
        //         putviews();
        //         viewRef.current = true;
        //     }
        // }

    };


    async function putviews() {

        if (details?.type === "review") {

            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS"
            }

            let bodyContent = {
                subId: details?.id,
                userId: user?.id ?? "user",
            }


            let reqOptions = {
                url: `https://reviewapi-zscu3untuq-el.a.run.app/putViewsOnReviews`,
                method: "PUT",
                headers: headersList,
                data: bodyContent
            }
            await axios.request(reqOptions).then(res => console.log(res?.data))
            details.views += 1
        }
        else {

            let headersList = {
                "Accept": "*/*",
                "Authorization": "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }

            let bodyContent = {
                subId: details?.id,
                userId: user?.id ?? "user",
            }


            let reqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/putViewsOnFlicks`,
                method: "PUT",
                headers: headersList,
                data: bodyContent
            }
            await axios.request(reqOptions).then(res => console.log(res?.data))
            details.views += 1
        }

    }


    async function sharereview() {

        navigator.share({
            url: "https://myrevue.app/viewReview/" + details?.type + "/" + details?.id,
            title: details?.name,
            text: `Hey! Check out this ${details?.type === "review" ? "review" : "flick"} on MyRevue app. Get the best ${details?.type === "review" ? "review" : "inspirational videos"} before buying anything on MyRevue! download the app.`
        })
    }

    return (
        <ReactVisibilitySensor onChange={onChange} >
            <div className={`h-full w-full snap-start relative `}>
                <div className="absolute w-full bg-gradient-to-b from-transparent  to-[#121212] bottom-0 flex justify-between p-3 gap-5 z-10">
                    <div className="flex-1 flex flex-col justify-end ">
                        <div className="flex w-full items-start gap-3">
                            <div className="flex-1 w-full flex gap-2">
                                <a href={`/product/${details?.product?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${details?.product?.id}`} rel="noopener noreferrer"  >
                                    <div className="h-12 w-12 rounded-full bg-white flex items-center justify-center">
                                        {details?.product?.imageUrl ? <img src={details?.product?.imageUrl} alt="" className=" h-full w-full rounded-full" />
                                            :
                                            <div className="text-xl">{details?.product?.name?.slice(0, 2).toUpperCase()}</div>
                                        }
                                    </div>
                                </a>
                                <div className="leading-5 flex-1 text-xs tracking-wide text-white font-light">
                                    <p className="">{details?.product?.name}</p>
                                    <p className="font-extralight tracking-wide text-white">{details?.user?.name}</p>
                                </div>
                            </div>
                            {details?.product?.affiliateLink && <div className="w-fit">
                                <a href={details?.product?.affiliateLink} rel="noopener noreferrer" target="_blank" >
                                    <button className="text-xs text-white border border-white appearance-none outline-none px-2 py-[6px] rounded-md">Buy Now</button>
                                </a>
                            </div>}
                        </div>
                        <div className={`text-white text-xs font-light tracking-wide mt-3 ${showDescription === false ? "line-clamp-1" : ""}`} onClick={() => setshowDescription(!showDescription)}>
                            {details?.description}
                        </div>
                    </div>
                    <div className="space-y-3">
                        <a href={`/profile/${details?.user?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${details?.user?.id}`} rel="noopener noreferrer"  >
                            <div className="h-12 w-12 bg-white rounded-full flex items-center justify-center text-base">
                                {details?.user?.image || details?.user?.imageUrl ? <img src={details?.user?.image ?? details?.user?.imageUrl} alt="" className=" h-full w-full rounded-full" />
                                    :
                                    <div className="text-xl">{details?.user?.name?.slice(0, 2).toUpperCase()}</div>
                                }
                            </div>
                        </a>
                        {details?.flickTag &&
                            <div className="flex flex-col items-center justify-center text-white">
                                <Heart size={22} color='currentcolor' />
                                <p className="font-extralight text-[#F5F5F5]">{details?.hearts ?? 0}</p>
                            </div>
                        }
                        <div className="flex flex-col items-center justify-center">
                            <Eye size={22} color='white' />
                            <p className="font-extralight text-[#F5F5F5]">{details?.views ?? 0}</p>
                        </div>
                        {!details?.flickTag &&
                            <div className="flex flex-col items-center justify-center text-white">
                                <Star size={22} color='currentcolor' weight='fill' />
                                <p className="font-extralight text-[#F5F5F5]">{details?.rating}</p>
                            </div>}

                        <div className="flex justify-center pb-3" onClick={() => sharereview()}>
                            <ShareNetwork size={22} color='white' />
                        </div>
                    </div>
                    {/* <p className="">{isVisible === true ? "visible" : "not visible"}</p> */}
                </div>
                <progress value={progress} max={100} id="progress_review" className="absolute z-40 bottom-0 w-full"></progress>

                <video
                    onClick={(e) => {
                        e.currentTarget.paused
                            ? e.currentTarget.play()
                            : e.currentTarget.pause();
                    }}
                    ref={vidRef}
                    loop
                    onTimeUpdate={handleOnTimeUpdate}
                    autoPlay
                    poster={details?.thumbnail}
                    src={visibility === true ? details?.reviewUrl : details?.thumbnail}
                    className='h-full w-full object-cover'>
                </video>


            </div>
        </ReactVisibilitySensor>

    )
}
