import React from 'react'

export default function NoPage() {

    return (
        <div className='flex flex-col items-center justify-center w-full h-full'>
            <p className="text-5xl font-semibold">404</p>
            <p className="text-lg">Page not found</p>
        </div>
    )
}
