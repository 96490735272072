import { Bell, CircleNotch, CloudSnow, MagnifyingGlass } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard'
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore/lite';
import { db } from '../firebase';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ReactVisibilitySensor from 'react-visibility-sensor';
import { useNavigate } from 'react-router-dom';
export default function Anypage() {
    const [tab, settab] = useState(1);
    const cats = ["cat_r6PVQbHb7tav2VJgqujJDA", "cat_kdik4uvKPxQPc1iG8Nmn4S", "cat_1HFWNd5hxVhgFCwBhTfusp", "cat_4s46eoAJiZUn1cVg2tQ8La", "cat_vrYhTy8pexBVqLgf3Cx9JK", "i-auB5-1", "Yk0kzq4W"]
    const [reviews, setreviews] = useState([])
    const [page, setpage] = useState(0)
    const [loading, setloading] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        setloading(true)
        async function getreviews() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS"
            }

            let reqOptions = {
                url: `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsByCategory/${cats[tab - 1]}/0`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                const array = res?.data.data;
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                setreviews(array)

                setloading(false)
                setpage(page + 1)
            }).catch((err) => console.log(err))
        }
        getreviews()
    }, [tab])


    function onChange(isVisible) {

        if (isVisible) {
            async function getmorereviews() {
                let headersList = {
                    "Accept": "*/*",
                    "Authorization": "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS"
                }

                let reqOptions = {
                    url: `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsByCategory/${cats[tab - 1]}/${page}`,
                    method: "GET",
                    headers: headersList,
                }

                await axios.request(reqOptions).then((res) => {
                    const array = res?.data.data;
                    for (let i = array.length - 1; i > 0; i--) {
                        const j = Math.floor(Math.random() * (i + 1));
                        [array[i], array[j]] = [array[j], array[i]];
                    }
                    setreviews([...reviews, ...array])
                    setpage(page + 1)
                }).catch((err) => console.log(err))
            }
            getmorereviews()
        }
    }



    return (
        <>
            <Helmet>
                <title>Reviews - MyRevue</title>
                <link rel="canonical" href="https://myrevue.app/reviews" />
                <meta name="title" content="Reviews - MyRevue" />
                <meta name="description" content="MyRevue is a platform where users can discover and share reviews on various products and services. Browse through our categories or search for reviews using keywords." />
                <meta name="keywords" content="MyRevue Reviews, Reviews, Product Reviews, Brand Reviews, Amazon Reviews, Flipkart Reviews, Amazon product reviews,  reviews, MyRevue reviews, reviews,movie reviews, movie recommendations, movies, search, categories, action, comedy, drama, horror, user-friendly" />
                <meta property="og:url" content="https://myrevue.app/reviews" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Reviews - MyRevue" />
                <meta property="og:description" content="MyRevue is a platform where users can discover and share reviews on various products and services. Browse through our categories or search for reviews using keywords." />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="myrevue.app" />
                <meta property="twitter:url" content="https://myrevue.app/reviews" />
                <meta name="twitter:title" content="Reviews - MyRevue" />
                <meta name="twitter:description" content="MyRevue is a platform where users can discover and share reviews on various products and services. Browse through our categories or search for reviews using keywords." />
            </Helmet>
            <header className="flex justify-between items-center h-[45px] px-[20px] bg-white headerShadow">
                <img src={require("../images/squareLogo.svg").default} alt="squarelogo" className='' />
                <img src={require("../images/textLogo.svg").default} alt="squarelogo" className='pl-5 h-7' />
                <div className="flex items-center gap-2">
                    <Bell size={22} onClick={() => navigate("/notifications")} />
                    <MagnifyingGlass size={22} color='black' onClick={() => navigate("/search")} />
                </div>
            </header>
            <section className="z-10 flex w-full px-3 py-2 overflow-x-auto bg-white border-b hidescroll">
                <button className={`px-4 py-2 ${tab === 1 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(1)}>Health & Beauty</button>
                <button className={`px-4 py-2 ${tab === 2 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(2)}>Movies</button>
                <button className={`px-4 py-2 ${tab === 3 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(3)}>Electronics</button>
                <button className={`px-4 py-2 ${tab === 4 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(4)}>Online Stores & Websites</button>
                <button className={`px-4 py-2 ${tab === 5 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(5)}>Stores & Shops</button>
                <button className={`px-4 py-2 ${tab === 6 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(6)}>Automobile</button>
                <button className={`px-4 py-2 ${tab === 7 ? "bg-navactive text-white" : "bg-white text-black"} text-xs tracking-wide rounded-md min-w-fit appearance-none outline-none select-none`} onClick={() => settab(7)}>Grocery & Gourmet Foods</button>
            </section>
            <section className='grid grid-cols-2 gap-2 p-2'>
                {loading === true ?
                    <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                    : <>
                        {reviews?.map((review, index) => (
                            <Reviewthumbnailcard details={review} key={index} />
                        ))}
                        <ReactVisibilitySensor onChange={onChange} >
                            <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        </ReactVisibilitySensor>
                    </>
                }
            </section>
        </>
    )
}
