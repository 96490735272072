import { collection, doc, getDoc, getDocs } from 'firebase/firestore/lite';
import { ArrowLeft } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { db } from '../firebase';

export default function Subcategories() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [subcats, setsubcats] = useState({})
    useEffect(() => {
        getDoc(doc(db, "category", id)).then((doc) => setsubcats(doc.data()))
    }, [id])
    return (
        <>
            <header className='flex items-center justify-between h-14 bg-white px-5 shadow-md' >
                <div className="h-9 w-9 " onClick={() => navigate(-1)}>
                    <ArrowLeft size={32} color="black" />
                </div>
                <div className="text-black">Sub Categories</div>
                <div className=""></div>
            </header>
            <section className='p-5 space-y-5'>
                {subcats?.images?.map((subcat, index) => (
                    <div className="w-full aspect-[21/8] relative" onClick={() => navigate(`/category/${subcats.name.replaceAll(" ", "-")}/${subcat?.name?.replaceAll(" ", "-")}/${subcats.id}`)} key={index}>
                        <div className="bg-[#121212d2] h-full w-full absolute rounded-xl flex items-center justify-center text-white font-medium tracking-wide">
                            {subcat?.name}
                        </div>
                        <img src={subcat?.icon} loading="lazy" alt="subcat-1" className="h-full w-full object-cover rounded-xl" />
                    </div>
                ))}
            </section>
        </>
    )
}
