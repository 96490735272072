import { ArrowLeft, CaretRight } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { auth } from '../firebase'

export default function Myaccountnav() {
    const navigate = useNavigate()
    const [user, setuser] = useState(JSON.parse(sessionStorage.getItem("user")))


    useEffect(() => {
        if (user !== null) return
        else {
            navigate("/")
        }
    }, [])


    async function sharereview() {

        navigator.share({
            text: `Hi, I use MyRevue to share all my reviews and experiences about products that I used & claim cash in the bank, free products & exclusive offers. Use this invite from me and earn 300 MyRevue coins and use to claim your rewards. Visit my profile: ${user.id}`
        })
    }

    function signout() {
        auth.signOut().then(() => {
            sessionStorage.removeItem("user")
            navigate("/")

        })
    }

    return (
        <div className=''>
            <header className="flex justify-between items-center h-[45px] px-[20px] bg-white headerShadow">
                <ArrowLeft size={22} onClick={() => navigate(-1)} />
                <p className="">My Account</p>
                <p className=""></p>
            </header>

            <section className='h-full flex-1 bg-white w-full p-3 flex flex-col justify-between'>
                <div className=""></div>
                <div className="text-sm text-center"></div>
                <div className="space-y-1">
                    <button className="h-16 w-full bg-navactive bg-opacity-0 hover:bg-opacity-10 rounded-md flex justify-between items-center" onClick={() => sharereview()}>
                        <div className="flex items-center">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img src={require("../images/account/share.png")} alt="icon" className="h-6 w-6" />
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="">Share My Profile</p>
                                <p className="text-sm">Refer and earn.</p>
                            </div>
                        </div>
                        <div className="">
                            <CaretRight size={18} color="#808080" weight="fill" />
                        </div>
                    </button>
                    <button className="h-16 w-full bg-navactive bg-opacity-0 hover:bg-opacity-10 rounded-md flex justify-between items-center" onClick={() => navigate("/feedback")}>
                        <div className="flex items-center" >
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img src={require("../images/account/rating.png")} alt="icon" className="h-6 w-6" />
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="">Feedback</p>
                                <p className="text-sm">give us feedback</p>
                            </div>
                        </div>
                        <div className="">
                            <CaretRight size={18} color="#808080" weight="fill" />
                        </div>
                    </button>
                    <a href="https://support.myrevue.app" target="_blank" rel='noreferrer noopener'>    <button className="h-16 w-full bg-navactive bg-opacity-0 hover:bg-opacity-10 rounded-md flex justify-between items-center">
                        <div className="flex items-center">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img src={require("../images/account/help.png")} alt="icon" className="h-6 w-6" />
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="">Help</p>
                                <p className="text-sm">Frequently asked questions</p>
                            </div>
                        </div>
                        <div className="">
                            <CaretRight size={18} color="#808080" weight="fill" />
                        </div>
                    </button>
                    </a>
                    <button className="h-16 w-full bg-navactive bg-opacity-0 hover:bg-opacity-10 rounded-md flex justify-between items-center" onClick={() => signout()}>
                        <div className="flex items-center">
                            <div className="h-16 w-16 flex items-center justify-center">
                                <img src={require("../images/account/logout.png")} alt="icon" className="h-6 w-6" />
                            </div>
                            <div className="flex flex-col items-start">
                                <p className="">Logout</p>
                                <p className="text-sm">Logout from this app</p>
                            </div>
                        </div>
                        <div className="">
                            <CaretRight size={18} color="#808080" weight="fill" />
                        </div>
                    </button>
                </div>
            </section >

        </div >
    )
}
