import { ArrowLeft, CircleNotch, List, VideoCamera } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard'
import { db } from '../firebase';
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore/lite';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import ReactVisibilitySensor from 'react-visibility-sensor';
import ProfileReviewthumbnailcard from '../components/cards/profilereviewthumbnailcard';

export default function Profile() {
    const [tab, settab] = useState(1);
    const navigate = useNavigate();
    var id;
    const [user, setuser] = useState({});
    const [reviews, setreviews] = useState([])
    const [flicks, setflicks] = useState([]);
    const [reviews_page, setreviews_page] = useState(0);
    const [reviews_loading, setreviews_loading] = useState(true);
    const [flicks_loading, setflicks_loading] = useState(true);
    const [flicks_page, setflicks_page] = useState(0)
    const [verifiedModal, setverifiedModal] = useState(false);
    useEffect(() => {
        var user = sessionStorage.getItem("user");

        if (user !== null) {
            setuser(JSON.parse(user));
            const user_ = JSON.parse(user);
            id = user_?.id
        }
        else {
            navigate("/");
            window.location.reload()
        }

        // const review_query = query(collection(db, "review"), where("uploadedBy.id", "==", id), where("status", "==", true))
        // getDocs(review_query).then(res => setreviews(res.docs.map((doc => doc.data()))));
        // const flicks_query = query(collection(db, "flicks"), where("uploadedBy.id", "==", id), where("status", "==", true))
        // getDocs(flicks_query).then(res => setflicks(res.docs.map((doc => doc.data()))))

        async function getuserreviews() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
            }

            let reqOptions = {
                url: `https://userapi-zscu3untuq-el.a.run.app/getUserReviews/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setreviews_page(reviews_page + 1)
                setreviews(res?.data?.data)
                setreviews_loading(false)
            }).catch((err) => console.log(err))
        }
        async function getuserflicks() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
            }

            let reqOptions = {
                url: `https://userapi-zscu3untuq-el.a.run.app/getUserFLicks/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setflicks_page(flicks_page + 1)
                setflicks(res?.data?.data)
                setflicks_loading(false)
            }).catch((err) => console.log(err))
        }
        getuserreviews()
        getuserflicks()
    }, []);
    async function getmoreuserreviews() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
        }

        let reqOptions = {
            url: `https://userapi-zscu3untuq-el.a.run.app/getUserReviews/${id}/${reviews_page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res?.data?.data.length === 0) {
                return setreviews_loading(null)
            }
            setreviews_page(reviews_page + 1)
            setreviews([...reviews, ...res?.data?.data])
            setreviews_loading(false)
        }).catch((err) => console.log(err))
    }
    async function getmoreuserflicks() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
        }

        let reqOptions = {
            url: `https://userapi-zscu3untuq-el.a.run.app/getUserFlicks/${id}/${flicks_page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res?.data?.data.length === 0) {
                return setflicks_loading(null)
            }
            setflicks_page(flicks_page + 1)
            // setflicks([...flicks, ...res?.data?.data])
            setflicks_loading(false)
        }).catch((err) => console.log(err))
    }

    async function onChangereviews(isVisible) {
        if (isVisible) {
            getmoreuserreviews()
        }
    }
    async function onChangeflicks(isVisible) {
        if (isVisible) {
            getmoreuserflicks()
        }
    }

    return (
        <>
            <header className="flex justify-between items-center h-[45px] px-[20px] bg-white headerShadow">
                <ArrowLeft size={22} onClick={() => navigate(-1)} />
                <p className="">Profile</p>
                <List size={22} onClick={() => navigate("/myaccount")} />
            </header>
            <section className="">
                <>
                    {verifiedModal === true ? <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                        <div
                            className="absolute w-full h-full bg-black cursor-pointer bg-opacity-80"
                            onClick={() => setverifiedModal(false)}
                        >
                        </div>
                        <div className="relative z-50 w-full">
                            <div className="bg-white w-full px-5 py-8 z-[600]">
                                <p className="text-xl text-center">Verified Account</p>
                                <p className="pt-3 text-sm text-center">This account is eligible for brand collaboration on MyRevue</p>
                            </div>
                        </div>
                    </div> : null}
                </>
                <div className="">
                    <img src={user?.coverImage ?? "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FEarn%20Rewards.webp?alt=media&token=136b4a56-93fb-4a21-a90a-f3f63b10a321"} alt="cover_image" className="w-full aspect-[21/6] object-cover" />
                </div>
                <div className="flex w-full px-5 gap-x-2">
                    <div className="border-2 border-white rounded-full h-28 w-28 -mt-14">
                        <img src={user.imageUrl ?? "https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fdefault_dp.png?alt=media&token=12a37164-51dc-48a8-9bf3-f008fa6a5113"} alt="profile_image" className="object-cover w-full h-full rounded-full" />
                    </div>
                    <div className="flex-1 overflow-hidden break-words">
                        <p className="text-xl font-semibold">{user?.name}</p>
                        <p className="text-sm">{user?.tag}</p>
                        <p className="text-sm">{user?.about}</p>
                    </div>
                </div>
            </section>
            <section className="grid grid-cols-4 divide-x-[1px] divide-[#12121240] px-5 pt-5">
                <div className="">
                    <p className="text-xl font-semibold text-center">{user?.reviews ?? 0}</p>
                    <p className="text-xs text-center">Reviews</p>
                </div>
                <div className="">
                    <p className="text-xl font-semibold text-center">{user?.flicks ?? 0}</p>
                    <p className="text-xs text-center">Flicks</p>
                </div>
                <div className="">
                    <p className="text-xl font-semibold text-center">{user?.followers ?? 0}</p>
                    <p className="text-xs text-center">Followers</p>
                </div>
                <div className="">
                    <p className="text-xl font-semibold text-center">{user.following === null ? 0 : user.following}</p>
                    <p className="text-xs text-center">Following</p>
                </div>
            </section>
            {/* <section className="flex items-center justify-center" >
                <button className="border-[2px] border-navactive py-3 px-16 rounded-lg text-navactive text-sm tracking-wide">
                    Edit Profile
                </button>
            </section> */}
            <section className="grid h-10 grid-cols-2 mt-4">
                <div className={`w-full h-full flex items-center justify-center border-b ${tab === 1 ? "border-navactive text-navactive" : "text-black"}`} onClick={() => settab(1)}>
                    <VideoCamera size={20} color="currentcolor" />
                </div>
                <div className={`w-full h-full flex items-center justify-center border-b ${tab === 2 ? "border-navactive text-navactive" : "text-black"}`} onClick={() => settab(2)}>
                    <img src={require("../images/reels.svg").default} alt="" className="w-6 h-5 invert" />
                </div>
            </section>
            <section className="p-2 pb-20">
                {tab === 1 && <div className="grid grid-cols-2 gap-3 mt-3 hidescroll">
                    {reviews_loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {reviews?.map((review, index) => (
                                <ProfileReviewthumbnailcard details={review} key={index} />
                            ))}
                            {reviews_loading !== null && <ReactVisibilitySensor onChange={onChangereviews} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>}
                {tab === 2 && <div className="grid grid-cols-2 gap-3 mt-3 hidescroll">
                    {flicks_loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {flicks?.map((review, index) => (
                                <ProfileReviewthumbnailcard details={review} key={index} />
                            ))}
                            {flicks_loading !== null && <ReactVisibilitySensor onChange={onChangeflicks} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>}
            </section>
        </>
    )
}
