import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Storyvideo from '../components/storyvideo';
import { CircleNotch } from 'phosphor-react';
import { useParams } from 'react-router-dom';

export default function Stories() {
    const [adds, setadds] = useState(JSON.parse(sessionStorage.getItem('ads')));
    const [loading, setloading] = useState(true);
    const { id } = useParams();
    const element = document.getElementById(id);

    useEffect(() => {
        setloading(true);
        if (!adds) {
            async function getstory() {
                let headersList = {
                    "Accept": "*/*",
                    "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
                }

                let reqOptions = {
                    url: "https://userspecificapi-zscu3untuq-el.a.run.app/getAppAds",
                    method: "GET",
                    headers: headersList,
                }

                await axios.request(reqOptions).then((res) => setadds(res?.data?.data)).catch((err) => console.log(err))
                setloading(false)
            }

            getstory()

        }
        else {

            setloading(false);

            // document.getElementById(id).scrollIntoView()
        }
    }, [id, adds])

    useEffect(() => {
        if (loading === false) {
            setTimeout(() => {
                document.getElementById(id).scrollIntoView()
            }, 100);
        }
    }, [id, loading]);


    return (
        <>
            <section className={`h-full w-full snap-y snap-mandatory overflow-y-scroll hidescroll `} id="reviews">

                {loading === true ? <CircleNotch size={32} color="#ffffff" className='animate-spin' />
                    :
                    adds.map((review, index) => (
                        <Storyvideo details={review} key={index} />
                    ))}
            </section>
        </>
    )
}
