import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export default function ProfileReviewthumbnailcard({ details }) {

    const navigate = useNavigate();
    const location = useLocation();
    async function changeroute(value) {
        sessionStorage.setItem("previousRoute", `${location.pathname}`)
        if (location.pathname.includes("product")) {
            sessionStorage.setItem("reviewsSortKind", "allreviews")
        }
        navigate(value)
        return
    }
    return (
        <div className="w-full aspect-[9/16] relative rounded-md" onClick={() => changeroute(`/viewReview/${details?.flickTag ? "flicks/" : "review/"}${details?.id}`)}>
            <div className="absolute h-full w-full rounded-md bg-gradient-to-b from-transparent via-transparent to-black flex flex-col justify-end">
                <div className="flex justify-between gap-2 w-full p-3">
                    <div className="h-8 w-8 rounded-full">
                        <img src={details?.product?.imageUrl} alt="review_image" className="h-full w-full object-cover rounded-full" />
                    </div>
                    <div className="w-[calc(100%-48px)] flex-1">
                        <p className="truncate text-xs text-white font-light">{details?.product?.name}</p>
                        <p className="truncate text-xs text-white font-extralight tracking-wide">{details?.user?.name}</p>
                    </div>
                </div>
            </div>
            <div className="h-full w-full">
                <img src={details?.coverImage ?? details?.thumbnail} loading="lazy" alt="review_image" className="h-full w-full object-cover rounded-md" />
            </div>
        </div>
    )
}
