import axios from 'axios';
import { ArrowLeft, CircleNotch, FlagCheckered, XCircle } from 'phosphor-react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import ReactVisibilitySensor from 'react-visibility-sensor';
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard';
import FlicksThumbnailcard from '../components/cards/flicksThumbnailcard';

export default function Flicksbytag() {
    const { tag, filtertag } = useParams();
    const [flicks, setflicks] = useState([]);
    const [term, setterm] = useState("");
    const [subflickstype, setsubflickstype] = useState([]);
    const [page, setpage] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        async function getflicks() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }

            let reqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksByTag/${tag}/0`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                setflicks(res?.data?.data);
                setpage(1)
            }).catch((err) => console.log(err))
        }
        async function getflcikstype() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getFlicksType/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                let uniqueArr = res?.data?.data?.filter((val) => {
                    if (val.type === tag.replaceAll("-", " ")) {
                        return val
                    }
                });
                setsubflickstype(uniqueArr)

            }).catch((err) => console.log(err))
        }
        getflcikstype()
        getflicks()
    }, [tag])


    function onChange(isVisible) {

        if (isVisible) {
            async function getflicks() {
                let headersList = {
                    "Accept": "*/*",
                    "Authorization": "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
                }

                let reqOptions = {
                    url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksByTag/${tag}/${page}`,
                    method: "GET",
                    headers: headersList,
                }
                await axios.request(reqOptions).then((res) => {
                    setflicks([...flicks, ...res?.data?.data]);
                    setpage(page + 1)
                }).catch((err) => console.log(err))
            }
            getflicks()
        }
    }

    return (
        <>
            <div className="p-3">
                <div className="h-12 w-full bg-[#F5F5F5] relative">
                    <div className="absolute z-20 flex items-center w-12 h-12 pl-4">
                        <ArrowLeft size={28} color="#404040" onClick={() => navigate(-1)} />
                        {/* <XCircle size={28} color="#404040" weight="fill" /> */}
                    </div>
                    <div className="absolute z-20 flex items-center justify-end w-12 h-12 right-4">
                        <XCircle size={28} color="#404040" weight="fill" onClick={() => setterm("")} />
                    </div>
                    <input type="text" value={term} onChange={(event) => setterm(event.target.value)} placeholder={`search in ${tag.replaceAll("-", " ")}`} className=" h-full w-full px-14 bg-transparent text-sm tracking-wide caret-[#6E41E2] focus:outline-none text-black absolute z-10" />
                </div>
            </div>
            <div className="grid grid-cols-2 gap-3 p-3">
                <div className="w-full aspect-[9/16] rounded-md">
                    <p className="text-3xl font-semibold">{subflickstype[0]?.type}</p>
                    {subflickstype?.map((tags, index) => (
                        <button onClick={() => navigate("/flickssearch/" + tags?.name.replaceAll(" ", "-"), { replace: true })} className="rounded-full text-white bg-[#EA1E63] px-2 py-1 text-xs my-1 tracking-wide text-left w-fit" key={index}>{tags?.name}</button>
                    ))}
                </div>
                {flicks?.map((flick, index) => (
                    <FlicksThumbnailcard details={flick} key={index} />
                ))}

                <ReactVisibilitySensor onChange={onChange} >
                    <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                </ReactVisibilitySensor>

            </div>
        </>
    )
}
