import { X } from 'phosphor-react'
import React from 'react'

export default function Downloadapp({ close }) {
    return (
        <div className="relative w-full p-3 bg-white rounded-lg">
            <div className="absolute pb-5 right-3 top-3">
                <X color='black' size={36} onClick={() => close(false)} />
            </div>
            <div className="flex flex-col items-center justify-center pt-10">
                <div className="relative rounded-lg shadow-md shadow-black">
                    <div className="absolute flex flex-col items-center justify-between w-full h-full py-2 text-sm">
                        <p className="font-medium text-white">Join MyRevue and get your first</p>
                        <div className="flex items-center gap-x-3 p-1 bg-[#D2C8E8] w-fit rounded-full mx-auto pr-3 ">
                            <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcoin.png?alt=media&amp;token=44f10712-9742-45f9-b27d-54f462710e6c" alt="coin" className='w-5 h-5' />
                            <p className="text-black ">300</p>
                        </div>
                        <p className="text-xs text-center text-white">Use coins to redeem cash,free products & exclusive discounts.</p>
                    </div>
                    <img src={require("../images/login.jpeg")} alt="" className="rounded-lg" />
                </div>
                <div className="mt-3 text-lg font-medium text-center">to continue..</div>
                <div className="flex items-center justify-center gap-5">
                    <a href="https://play.google.com/store/apps/details?id=com.myrevue.app" className='w-full' target="_blank" rel="noopener noreferrer">
                        <img src={require("../images/play store.png")} alt="" className="h-12" />
                    </a>
                    <a href="https://apps.apple.com/in/app/myrevue-product-discovery-app/id6448450142" className='w-full' target="_blank" rel="noopener noreferrer">
                        <img src={require("../images/app store.png")} alt="" className="h-12" />
                    </a>
                </div>
            </div>
        </div>
    )
}
