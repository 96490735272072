import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function ProfileCard({ details }) {
    let navigate = useNavigate();

    return (
        <div className="flex flex-col items-center" onClick={() => navigate(`/profile/${details?.name.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${details.id}`)}>
            <div className="bg-white h-[80px] w-[80px] rounded-full flex items-center justify-center border p-[1px]">
                <img src={details.imageUrl} loading="lazy" alt="profile-1" className="h-full w-full rounded-full object-cover" />
            </div>
            <div className="mt-1">
                <p className="text-navactive text-center font-medium text-xs">{details.name?.replace(/\b\w/g, l => l.toUpperCase()).split(" ")[0]}</p>
            </div>
        </div>
    )
}
