import { CircleNotch, House, HouseSimple, Phone, Plus, ShieldSlash, UserCircle, VideoCamera, X } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import {
    GoogleAuthProvider,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    signInWithPopup,
} from "firebase/auth";

import { auth, } from '../firebase';
import Downloadapp from '../components/downloadapp';
import axios from 'axios';
import { nanoid } from 'nanoid';
import { toast } from 'react-toastify';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function Layout() {

    let navigate = useNavigate();
    let location = useLocation();
    const [signin, setsignin] = useState(false);
    const [loggedin, setloggedin] = useState(false)
    const [plusactive, setplusactive] = useState(false)
    const [user, setuser] = useState(JSON.parse(sessionStorage.getItem("user")))
    const [phone, setphone] = useState(null);
    const [otp, setotp] = useState(null)
    const [verify_otp, setverify_otp] = useState(false)
    const [downloadapp, setdownloadapp] = useState(false);
    const [loading, setloading] = useState(false)

    useEffect(() => {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {
        } else {
            if (location.pathname.includes("timeline") || location.pathname.includes("subcategory")) {
                return window.location.replace("https://myrevue.app/home")
            }
            if (location.pathname.includes("flicksby")) {
                return window.location.replace("https://myrevue.app/flicks")
            }
            if (location.pathname.includes("search")) {
                return window.location.replace("https://myrevue.app/search/?query=")
            }
            else {
                return window.location.replace("https://myrevue.app" + location.pathname)
            }
        }
        document.getElementById("scroll").scrollTo(0, 0)
        if (user !== null) {
            setloggedin(true)
        }
        window.gtag('config', "G-LNC4PTHDN7", { page_path: location.pathname })

    }, [location]);

    const getotp = (event) => {
        event.preventDefault();
        const phonenumber = phone;
        // return
        window.recaptchaVerifier = new RecaptchaVerifier('sign-in-button', {
            'size': 'invisible',
            'callback': (response) => {
                // reCAPTCHA solved, allow signInWithPhoneNumber.
                // onSignInSubmit();
            }
        }, auth);
        const appVerifier = window.recaptchaVerifier;
        signInWithPhoneNumber(auth, phonenumber, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setverify_otp(true)
            }).catch((error) => {
                console.log(error)
            });
    }
    const verifyotp = (event) => {
        event.preventDefault();
        if (otp.length < 6) {
            toast.warning('Please enter correct otp', {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-sm tracking-wide font-work"
            });
            return;
        }
        let confirmationResult = window.confirmationResult;
        confirmationResult.confirm(otp).then(async (result) => {
            const user = result.user;
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
                "Content-Type": "application/json"
            }

            let bodyContent = {
                "email": "@",
                "phone": user?.phoneNumber
            };

            let reqOptions = {
                url: "https://userapi-zscu3untuq-el.a.run.app/amIExist/",
                method: "PUT",
                headers: headersList,
                data: bodyContent,
            }

            const amIExist = await axios.request(reqOptions).then((res) => res?.data).catch(err => console.log(err))

            if (amIExist?.success === false) {
                toast.warning('No account is associated with this email. Please download the MyRevue App and sign up for a personalized profile.', {
                    position: "top-right",
                    // theme: "dark",
                    icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                    bodyClassName: "text-md tracking-wide font-work"
                });
                setloading(false);
                return
            }
            else {
                sessionStorage.setItem("user", JSON.stringify(amIExist.user));
                await axios.get(`https://mainapi2-zscu3untuq-el.a.run.app/timeline/${user.id}/0`, {
                    headers: {
                        'authorization': 'alsdfhqp94LNSLKHFSDFDFartoq34u509rajlgakdng2'
                    }
                }).then(response => {
                    response.data?.data.forEach((rev, index) => {
                        rev.type = rev?.flickTag ? 'flicks' : 'review'
                    })
                    sessionStorage.setItem('flicskTimline', JSON.stringify(response?.data?.data))
                }).catch(error => {
                    console.log(error);
                });
                setloading(false);
                window.location.reload();
            }

        }).catch((error) => {
            toast.warning(error.message, {
                position: "top-right",
                // theme: "dark",
                icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                bodyClassName: "text-md tracking-wide font-work"
            });
        });
    }

    const signInByPopUp = () => {
        const provider = new GoogleAuthProvider();
        setloading(true);
        signInWithPopup(auth, provider)
            .then(async (response) => {
                if (response.user) {
                    let user = response.user;
                    let headersList = {
                        "Accept": "*/*",
                        "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD",
                        "Content-Type": "application/json"
                    }

                    let bodyContent = {
                        "email": user?.email,
                        "phone": "+91"
                    };

                    let reqOptions = {
                        url: "https://userapi-zscu3untuq-el.a.run.app/amIExist/",
                        method: "PUT",
                        headers: headersList,
                        data: bodyContent,
                    }

                    const amIExist = await axios.request(reqOptions).then((res) => res?.data).catch(err => console.log(err))
                    if (amIExist?.success === false) {
                        toast.warning('No account is associated with this email. Please download the MyRevue App and sign up for a personalized profile.', {
                            position: "top-right",
                            // theme: "dark",
                            icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                            bodyClassName: "text-md tracking-wide font-work"
                        });
                        setloading(false);
                        return
                    }
                    else {
                        sessionStorage.setItem("user", JSON.stringify(amIExist.user));
                        await axios.get(`https://mainapi2-zscu3untuq-el.a.run.app/timeline/${user.id}/0`, {
                            headers: {
                                'authorization': 'alsdfhqp94LNSLKHFSDFDFartoq34u509rajlgakdng2'
                            }
                        }).then(response => {
                            response.data?.data.forEach((rev, index) => {
                                rev.type = rev?.flickTag ? 'flicks' : 'review'
                            })
                            sessionStorage.setItem('flicskTimline', JSON.stringify(response?.data?.data))
                        }).catch(error => {
                            console.log(error);
                        });
                        setloading(false);
                        window.location.reload();
                    }
                } else {
                    toast.warning('credentials not valid', {
                        position: "top-right",
                        // theme: "dark",
                        icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                        bodyClassName: "text-sm tracking-wide font-work"
                    });
                    return
                }
            })
            .catch((error) => {
                setloading(false);
                toast.warning(error.message, {
                    position: "top-right",
                    // theme: "dark",
                    icon: <ShieldSlash size={32} color="#ea202a" weight="bold" />,
                    bodyClassName: "text-sm tracking-wide font-work"
                });
                return
            });
    };

    function changeroute(str) {
        setdownloadapp(false);
        setsignin(false);
        setplusactive(false);
        navigate(str)
    }

    function setcloseall() {
        setsignin(false);
        setplusactive(false);
        setdownloadapp(false);
    }



    return (
        <>
            <ToastContainer toastStyle={{ backgroundColor: "#2a2a2a", color: "white" }} />

            {plusactive === true ?
                <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                    <div
                        className=" absolute h-full w-full cursor-pointer bg-black bg-opacity-80"
                        onClick={() => setcloseall()}
                    >
                    </div>
                    <div className="z-50 w-full relative">
                        <div className="absolute w-full p-5 -bottom-1">
                            {downloadapp === true && <Downloadapp close={setcloseall} />}
                        </div>
                        <div className="p-5 z-50">
                            <div className="bg-navactive w-full rounded-lg  p-5 space-y-5 ">
                                <button onClick={() => setdownloadapp(true)} className="gap-x-5 mx-auto w-[85%] text-sm flex justify-center bg-white h-12 rounded-md items-center text-navactive">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                        <path d="M14 2C7.373 2 2 7.373 2 14C2 20.627 7.373 26 14 26C20.627 26 26 20.627 26 14C26 7.373 20.627 2 14 2ZM0 14C0 6.268 6.268 0 14 0C21.732 0 28 6.268 28 14C28 21.732 21.732 28 14 28C6.268 28 0 21.732 0 14ZM8 10C8 9.46957 8.21071 8.96086 8.58579 8.58579C8.96086 8.21071 9.46957 8 10 8H18C18.5304 8 19.0391 8.21071 19.4142 8.58579C19.7893 8.96086 20 9.46957 20 10V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H10C9.46957 20 8.96086 19.7893 8.58579 19.4142C8.21071 19.0391 8 18.5304 8 18V10Z" fill="url(#paint0_linear_252_2340)" />
                                        <defs>
                                            <linearGradient id="paint0_linear_252_2340" x1="25.1568" y1="-3.23359" x2="2.59805" y2="28.8645" gradientUnits="userSpaceOnUse">
                                                <stop stop-color="#6E41E2" />
                                                <stop offset="1" stop-color="#E452FC" />
                                            </linearGradient>
                                        </defs>
                                    </svg>
                                    <p className="font-medium text-base">Record a Review</p>
                                </button>
                                <button onClick={() => setdownloadapp(true)} className="gap-x-5 mx-auto w-[85%] text-sm flex justify-center bg-white h-12 rounded-md items-center text-navactive">
                                    <img src={require("../images/reelsblue.svg").default} alt="" className="h-6 w-6" />
                                    <p className="font-medium text-base">Post Flicks</p>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            {signin === true ?
                <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                    <div
                        className=" absolute h-screen w-full cursor-pointer bg-black bg-opacity-80"
                        onClick={() => setcloseall()}
                    >
                    </div>
                    <div className="z-50 w-full relative">
                        <div className="bg-white w-full p-5 space-y-5 z-[70] rounded-t-2xl">
                            <div className="flex justify-end" onClick={() => { setcloseall() }}><X color='#000000' size={32} /></div>
                            <div className="relative  shadow-md shadow-black rounded-lg">
                                <div className="absolute h-full w-full flex flex-col justify-between items-center text-sm py-2">
                                    <p className="font-medium text-white">Join MyRevue and get your first</p>
                                    <div className="flex items-center gap-x-3 p-1 bg-[#D2C8E8] w-fit rounded-full mx-auto pr-3 ">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fcoin.png?alt=media&amp;token=44f10712-9742-45f9-b27d-54f462710e6c" alt="coin" className='h-5 w-5' />
                                        <p className="text-black ">300</p>
                                    </div>
                                    <p className="text-center text-xs text-white">Use coins to redeem cash,free products & exclusive discounts.</p>
                                </div>
                                <img src={require("../images/login.jpeg")} alt="" className="rounded-lg" />
                            </div>
                            <div className="mt-5">
                                <p className="font-semibold text-sm text-center">{verify_otp === false ? "Login to MyRevue" : "Enter Otp to continue"}</p>
                                <div className="" id="sign-in-button"></div>
                                <div className="bg-[#f5f5f5] h-12 mt-2 w-full relative flex justify-between items-center rounded-md px-3">
                                    <div className="">
                                        <Phone size={24} color="#828282" />
                                    </div>
                                    <div className="flex-1 pl-4">
                                        {verify_otp === false ? <form onSubmit={getotp}>
                                            <input type="text" defaultValue="+91" className="bg-transparent h-full w-full appearance-none outline-none " placeholder='ex: +91XXXXXXXXXX' onChange={(event) => setphone(event.target.value)} />
                                        </form> : null}
                                        {verify_otp === true ? <form onSubmit={verifyotp}>
                                            <input type="text" value={otp} className="bg-transparent h-full w-full appearance-none outline-none" placeholder='ex: XXXXXX' onChange={(event) => setotp(event.target.value)} />
                                        </form>
                                            : null}
                                    </div>
                                </div>
                                <div className="mt-2 text-center text-sm">OR</div>
                                <div className="mt-2">
                                    <button onClick={() => loading === true ? null : signInByPopUp()} className="border-[2px] border-[#383838] w-full h-12 rounded-full text-[#171717] flex gap-x-2 text-lg items-center justify-center">
                                        {loading === true ? <CircleNotch size={28} color='black' className='animate-spin' />
                                            : <>
                                                <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2FGoogle__G__Logo.svg.png?alt=media&token=693d692a-9a33-4720-8a94-6e3a35810d8d" alt="google" className='h-7 w-7 ' />
                                                Sign in with Google
                                            </>}
                                    </button>
                                </div>
                                <div className="mt-2">
                                    <p className="text-sm text-center ">By continuing you agree to MyRevue's</p>
                                    <div className="flex items-center justify-center gap-x-3 text-[#267df0]">
                                        <a target="_blank" href="https://myrevue.app/terms-and-conditions" rel='noopener noreferrer' className='text-sm'>
                                            Terms of Service
                                        </a>
                                        <a target="_blank" href="https://myrevue.app/privacy-policy" rel='noopener noreferrer' className='text-sm'>
                                            Privacy Policy
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : null}
            <main id="scroll" className='min-height overflow-y-auto' >
                <Outlet />
            </main>
            <nav className=''>

                <div className="h-12 bg-black w-full flex items-center justify-evenly z-50">
                    <button className={`${location.pathname.includes("reviews") ? "text-navactive navactive" : "text-white"}`} onClick={() => changeroute('/reviews')}>
                        <VideoCamera size={22} color="currentcolor" />

                    </button>
                    <button className={`${location.pathname === "/" ? "text-navactive navactive" : "text-white"}`} onClick={() => changeroute('/')}>
                        <HouseSimple size={22} color="currentcolor" />
                    </button>

                    <button className={`h-8 w-8 ${plusactive === true ? "bg-navactive text-white" : "bg-white text-navactive"} rounded-full flex items-center justify-center`} onClick={() => setplusactive(!plusactive)} >
                        {plusactive === false ?
                            <Plus size={22} color="currentcolor" />
                            :
                            <X size={22} color="currentcolor" />
                        }
                    </button>
                    {loggedin === false ? <button className="" onClick={() => setsignin(!signin)}>
                        <img src={require("../images/reels.svg").default} alt="" className="h-6 w-6" />
                        {/* <Play size={32} color="currentcolor" /> */}
                    </button>
                        :
                        <button className={`${location.pathname.includes("timeline") ? "text-navactive navactive" : "text-white"}`} onClick={() => changeroute('/timeline')}>
                            {location.pathname.includes("timeline") ? <img src={require("../images/reelsblue.svg").default} alt="" className="h-5 w-5" /> : <img src={require("../images/reels.svg").default} alt="" className="h-5 w-5" />}
                        </button>
                    }

                    {loggedin === false ? <button className="text-white " onClick={() => setsignin(!signin)}>
                        <UserCircle size={22} color="currentcolor" />
                    </button>
                        :
                        <button className={`${location.pathname.includes("/profile") ? "text-navactive navactive" : "text-white"}`} onClick={() => changeroute("/profile")}>
                            <UserCircle size={22} color="currentcolor" />
                        </button>
                    }
                </div>

            </nav>
        </>
    )
}
