import { addDoc, collection } from 'firebase/firestore/lite';
import { ArrowLeft } from 'phosphor-react'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../firebase';

export default function Feedback() {
    const navigate = useNavigate();
    const [user, setuser] = useState(JSON.parse(sessionStorage.getItem('user')))
    const [feedback, setfeedback] = useState('')

    function submit() {
        // if (feedback_details.reason.length < 10) {
        //     return alert("Please add reason/issue more than 10 characters")
        // }
        if (!user) {
            return alert("Please signin to share feedback")
        }
        if (feedback.length < 20) {
            return alert("Please add feedback in more than 20 characters")
        }
        addDoc(collection(db, "feedback"), {
            id: user?.id,
            name: user?.name,
            // title: feedback_details.reason,
            feedback: feedback
        }).then(() => navigate("/")).catch(err => alert(err));
    }


    return (
        <>
            <header className="flex justify-between items-center h-[60px] px-[20px] bg-white headerShadow">
                <ArrowLeft size={32} onClick={() => navigate(-1)} />
                <p className="">App Feedback</p>
                <p className=""></p>
            </header>
            <section className="">
                <div className="bg-white p-3 mt-3">
                    <p className="font-semibold text-navactive pb-2">Feedback</p>
                    <textarea className='border w-full h-44 p-2' onChange={(event) => setfeedback(event.target.value)} />
                    <button className="h-12 bg-navactive w-full text-white text-sm rounded tracking-wide" onClick={() => submit()}>Submit Feedback</button>
                </div>
            </section>
        </>
    )
}
