import axios from 'axios';
import { ArrowLeft, CircleNotch, FlagCheckered, XCircle } from 'phosphor-react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import ReactVisibilitySensor from 'react-visibility-sensor';
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard';
import FlicksThumbnailcard from '../components/cards/flicksThumbnailcard';

export default function Flicksbytag() {
    const { sentence } = useParams();
    const [flicks, setflicks] = useState([]);
    const [term, setterm] = useState(sentence?.replaceAll("-", " "));
    const [subflickstype, setsubflickstype] = useState([]);
    const [page, setpage] = useState(0)
    const navigate = useNavigate();
    useEffect(() => {
        async function getflicks() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }

            let reqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/searchFlicks/${sentence}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                setflicks(res?.data?.data);
                setpage(1)
            }).catch((err) => console.log(err))
        }
        async function getflcikstype() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getFlicksType/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                let uniqueArr = res?.data?.data?.filter((val) => {
                    if (val.name === sentence?.replaceAll("-", " ")) {
                        return val
                    }
                });
                let listarr = res?.data?.data?.filter((val) => {
                    if (val?.type === uniqueArr[0].type) {
                        return val
                    }
                });
                setsubflickstype(listarr)

            }).catch((err) => console.log(err))
        }
        getflcikstype()
        getflicks()
    }, [sentence])



    return (
        <>
            <div className="p-3">
                <div className="h-12 w-full bg-[#F5F5F5] relative">
                    <div className="h-12 absolute z-20 flex items-center pl-4 w-12">
                        <ArrowLeft size={28} color="#404040" onClick={() => navigate(-1)} />
                        {/* <XCircle size={28} color="#404040" weight="fill" /> */}
                    </div>
                    <div className="h-12 absolute right-4 justify-end z-20 flex items-center w-12">
                        <XCircle size={28} color="#404040" weight="fill" onClick={() => setterm("")} />
                    </div>
                    <form onSubmit={(event) => {
                        event.preventDefault();
                        setterm(term?.trim()?.replaceAll("-", " "))
                        navigate(`/flickssearch/${term?.trim()?.replaceAll(" ", "-")}`);
                    }}>
                        <input type="text" value={term} onChange={(event) => setterm(event.target.value)} placeholder={`search in ${sentence?.replaceAll("-", " ")}`} className=" h-full w-full px-14 bg-transparent text-sm tracking-wide caret-[#6E41E2] focus:outline-none text-black absolute z-10" />
                    </form>
                </div>
            </div>
            <div className="grid grid-cols-2 p-3 gap-3">
                <div className="w-full aspect-[9/16] rounded-md">
                    <p className="text-3xl font-semibold">{subflickstype[0]?.type}</p>
                    {subflickstype?.map((tags, index) => (
                        <button onClick={() => navigate("/flickssearch/" + tags?.name?.replaceAll(" ", "-"), { replace: true })} className="rounded-full text-white bg-[#EA1E63] px-2 py-1 text-xs my-1 tracking-wide text-left w-fit" key={index}>{tags?.name}</button>
                    ))}
                </div>
                {flicks?.map((flick, index) => (
                    <FlicksThumbnailcard details={flick} key={index} />

                ))}

            </div>
        </>
    )
}
