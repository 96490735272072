import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Reviewvideo from '../components/reviewvideo';
import Downloadapp from '../components/downloadapp';
import { CircleNotch } from 'phosphor-react';

export default function Timeline() {
    const navigate = useNavigate()
    const [tab, settab] = useState(1);
    const [flicks, setflicks] = useState([])
    const [following, setfollowing] = useState([]);
    const [foryou, setforyou] = useState([]);
    const [user, setuser] = useState(JSON.parse(sessionStorage.getItem("user")));
    const [creators, setcreators] = useState([]);
    const [downloadapp, setdownloadapp] = useState(false);
    useEffect(() => {
        if (user === null) {
            navigate("/");
            window.location.reload()
            return
        }

        async function flicks() {
            const flicks = JSON.parse(sessionStorage.getItem('flicskTimline'));
            flicks.forEach((flick) => {
                flick.type = flick?.flickTag ? 'flicks' : 'review'
            })
            setflicks(flicks)
        }
        async function timlineforyou() {
            await axios.get(`https://mainapi2-zscu3untuq-el.a.run.app/timelineForYou/${user.id}/0`, {
                headers: {
                    'authorization': 'alsdfhqp94LNSLKHFSDFDFartoq34u509rajlgakdng2'
                }
            }).then(response => {
                response.data?.data.forEach((rev, index) => {
                    rev.type = rev?.flickTag ? 'flicks' : 'review'
                });
                setfollowing(response.data?.data)
            }).catch(error => {
                console.log(error);
            });
        }
        async function timelineFollowing() {
            await axios.get(`https://mainapi2-zscu3untuq-el.a.run.app/timelineFollowing/${user.id}/0`, {
                headers: {
                    'authorization': 'alsdfhqp94LNSLKHFSDFDFartoq34u509rajlgakdng2'
                }
            }).then(response => {
                response.data?.data.forEach((rev, index) => {
                    rev.type = rev?.flickTag ? 'flicks' : 'review'
                });
                setforyou(response.data?.data)
            }).catch(error => {
                console.log(error);
            });
        }
        async function gettopbcreators() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getTopUser/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setcreators(res?.data?.data);
            }).catch((err) => console.log(err))
        }
        Promise.all([flicks(), timelineFollowing(), timlineforyou(), gettopbcreators()])
    }, [])

    function scrolltotab(num) {
        if (num === 1) {
            settab(num)
            document.getElementById("tab1").scrollIntoView()
            return
        }
        else if (num === 2) {
            settab(num)
            document.getElementById("tab2").scrollIntoView()
            return
        }
        else if (num === 3) {
            settab(num)
            document.getElementById("tab3").scrollIntoView()
            return
        }
    }


    return (
        <>
            {downloadapp === true && <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-[calc(100%-64px)] w-full items-end select-none">
                <div
                    className=" absolute h-screen w-full cursor-pointer bg-black bg-opacity-80"
                    onClick={() => setdownloadapp(false)}
                >
                </div>
                <div className="z-50 w-full relative">
                    <div className="absolute w-full p-5 -bottom-1">
                        <Downloadapp close={setdownloadapp} />
                    </div>
                </div>
            </div>}
            <div className="absolute top-0 pt-4 pb-8 w-full flex justify-center divide-x-[1px] gap-5 text-white divide-[#e0e0e0] z-50 bg-gradient-to-b from-[#121212] via-[#12121233] to-transparent">
                <div onClick={() => scrolltotab(1)} className={`${tab === 1 ? "text-white" : "text-[#e0e0e08c]"} cursor-pointer font-medium tracking-wide`}>Flicks</div>
                <div onClick={() => scrolltotab(2)} className={`${tab === 2 ? "text-white" : "text-[#e0e0e08c]"} cursor-pointer font-medium tracking-wide pl-5 `}>Following</div>
                <div onClick={() => scrolltotab(3)} className={`${tab === 3 ? "text-white" : "text-[#e0e0e08c]"} cursor-pointer font-medium tracking-wide pl-5 `}>For You</div>
            </div>
            <div className="flex snap-x snap-mandatory h-full w-full mx:auto overflow-scroll hidescroll" >
                <div className="snap-start shrink-0 grid w-full h-full place-items-center" id="tab1">
                    <ReactVisibilitySensor onChange={(isVisible) => isVisible === true ? settab(1) : null}>
                        <div className=" h-full w-full overflow-y-auto hidescroll snap-y snap-mandatory scroll-smooth">
                            {flicks?.map((review, index) => (
                                <Reviewvideo details={review} key={index} />
                            ))}
                        </div>
                    </ReactVisibilitySensor>
                </div>
                <div className="snap-start shrink-0 grid w-screen h-full place-items-center" id="tab2">
                    <ReactVisibilitySensor onChange={(isVisible) => isVisible === true ? settab(2) : null}>
                        <>
                            {following.length === 0 ?
                                <div className="h-full w-full bg-[#121212] pt-24 px-3">
                                    {creators?.map((creator, index) => (
                                        <div className="w-full flex items-center justify-between my-3" onClick={() => setdownloadapp(true)} key={index}>
                                            <div className="flex items-center gap-2">
                                                <div className="h-14 w-14 rounded-full">
                                                    <img src={creator?.imageUrl} alt="" className="h-full w-full rounded-full" />
                                                </div>
                                                <p className="text-white tracking-wide">{creator?.name?.replace(/\b\w/g, l => l.toUpperCase())}</p>
                                            </div>
                                            <div className="">
                                                <button className="py-2 px-6 rounded-md border border-navactive text-navactive">Follow</button>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                :
                                <div className=" h-full w-full overflow-y-auto hidescroll snap-y snap-mandatory">
                                    {following?.map((review, index) => (
                                        <Reviewvideo details={review} key={index} />
                                    ))}
                                </div>
                            }

                        </>
                    </ReactVisibilitySensor>
                </div>
                <div className="snap-start shrink-0 grid w-screen h-full place-items-center" id="tab3">
                    <ReactVisibilitySensor onChange={(isVisible) => isVisible === true ? settab(3) : null}>
                        <>
                            {foryou.length === 0 ?
                                <div className="h-full w-full bg-[#121212] pt-24 px-3 flex items-center justify-center">
                                    <p className="text-white">Follow brands to watch reviews</p>
                                </div> :
                                <div className=" h-full w-full overflow-y-auto hidescroll snap-y snap-mandatory">
                                    {foryou?.map((review, index) => (
                                        <Reviewvideo details={review} key={index} />
                                    ))}
                                </div>
                            }
                        </>
                    </ReactVisibilitySensor>
                </div>
            </div>
        </>
    )
}
