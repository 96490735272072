import axios from 'axios';
import { ArrowLeft, CircleNotch } from 'phosphor-react';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import HuntCard from '../components/cards/huntcard';

export default function Hunts() {
    const [loading, setLoading] = useState(true);
    const [campaigns, setCampaigns] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        getHunts()
    }, [])
    async function getHunts() {
        try {
            let headersList = {
                Accept: "*/*",
                Authorization: "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
            };

            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/getAllHouseRequestsForUser/user_KC0L6rlD`,
                method: "GET",
                headers: headersList,
            };

            const response = await axios.request(reqOptions)
            if (response.data.success) {
                setCampaigns(response.data.data);
                setLoading(false);
                return
            }
        } catch (error) {
            console.log(error.message)
        }
    }
    return (
        <>
            {loading ?
                <div className='flex items-center justify-center w-full h-full'>
                    <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                </div> :
                <div className='w-full'>
                    <header className="flex justify-between items-center h-[45px] px-[20px] bg-white headerShadow">
                        <ArrowLeft size={22} onClick={() => navigate(-1)} />
                        <p className="">Hunt</p>
                        <p className=""></p>
                    </header>

                    <div className="p-3">
                        {campaigns?.map((campaign, index) => (
                            <HuntCard key={index} data={campaign} />
                        ))}
                    </div>
                </div>
            }
        </>
    )
}
