import axios from 'axios';
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, limit, query, where } from 'firebase/firestore/lite';
import { ArrowLeft, CircleNotch } from 'phosphor-react'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import ProductCard from '../components/cards/productcard';
import Reviewthumbnailcard from '../components/cards/reviewthumbnailcard';
import Ratingcomponent from '../components/ratingcomponent';

export default function Subcategories() {
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setloading] = useState(true);
    const [product_loading, setproduct_loading] = useState(true)
    const [brand, setbrand] = useState({});
    const [products, setproducts] = useState([]);
    const [reviews, setreviews] = useState([])
    const [viewallproducts, setviewallproducts] = useState(false);
    const [page, setpage] = useState(0);
    const [productpage, setproductpage] = useState(true)
    useEffect(() => {
        async function getbrand() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g"
            }

            let reqOptions = {
                url: `https://brandapi-zscu3untuq-el.a.run.app/getBrand/${id}`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setbrand(res?.data?.brand)
            }).catch((err) => console.log(err))
        }
        async function getbrandproduct() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g"
            }

            let reqOptions = {
                url: `https://brandapi-zscu3untuq-el.a.run.app/getBrandProducts/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setproducts(res?.data?.data)
                setproductpage(productpage + 1)
                setproduct_loading(false)
            }).catch((err) => console.log(err))
        }
        async function getbrandreviews() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g"
            }

            let reqOptions = {
                url: `https://brandapi-zscu3untuq-el.a.run.app/getReviewsByBrand/${id}/0`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setpage(page + 1)
                setreviews(res?.data?.data)
                setloading(false)
            }).catch((err) => console.log(err))
        }
        Promise.all([getbrand(), getbrandproduct(), getbrandreviews()])
    }, [id]);

    async function getmorereviews() {
        let headersList = {
            "Accept": "*/*",
            "Authorization": "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g"
        }

        let reqOptions = {
            url: `https://brandapi-zscu3untuq-el.a.run.app/getReviewsByBrand/${id}/${page}`,
            method: "GET",
            headers: headersList,
        }

        await axios.request(reqOptions).then((res) => {
            if (res.data?.data.length === 0) {
                return setloading(null)
            }
            setpage(page + 1)
            setreviews([...reviews, ...res?.data?.data])
        }).catch((err) => console.log(err))
    }
    function onChange(isVisible) {

        if (isVisible) {
            getmorereviews()
        }
    }

    function onChangeproduct(isVisible) {
        if (isVisible) {
            setproduct_loading(null)
            // getmoreproducts()
        }
    }
    return (
        <>
            <Helmet>
                <title>{`${brand?.name} - MyRevue`}</title>
                <link rel="canonical" href={`https://myrevue.app/brand/${brand?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${brand?.id}`} />
                <meta name="title" content={`${brand?.name} - MyRevue`} />
                <meta name="description" content={brand?.description} />
                <meta name="keywords" content={`${brand?.name}, ${brand?.parentName}, MyRevue, MyRevue Products, MyRevue - ${brand?.subcategory}`} />
                <meta property="og:url" content={`https://myrevue.app/brand/${brand?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${brand?.id}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${brand?.name} - MyRevue`} />
                <meta property="og:description" content={`${brand?.name}, ${brand?.parentName}, MyRevue, MyRevue Products, MyRevue - ${brand?.subcategory}`} />
                <meta property="og:image" content={brand?.images?.xs ?? brand?.imageUrl} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content="myrevue.app" />
                <meta property="twitter:url" content={`https://myrevue.app/brand/${brand?.name?.trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${brand?.id}`} />
                <meta name="twitter:title" content={`${brand?.name} - MyRevue`} />
                <meta name="twitter:description" content={`${brand?.name}, ${brand?.parentName}, MyRevue, MyRevue Products, MyRevue - ${brand?.subcategory}`} />
                <meta name="twitter:image" content={brand?.images?.xs ?? brand?.imageUrl} />
            </Helmet>
            <header className='flex items-center justify-between h-[45px] bg-white px-5 shadow-md'>
                <div className="w-6 h-6 " onClick={() => navigate(-1)}>
                    <ArrowLeft size={22} color="black" />
                </div>
                <div className="text-black">{brand?.name}</div>
                <div className=""></div>
            </header>

            <section className='w-full mt-2'>
                <div className="w-full">
                    <div className="">
                        <img src={brand?.coverImage} alt="cover_image" className="w-full" />
                    </div>
                    <div className="flex items-center gap-2 px-5 mt-5">
                        <div className="h-10 w-10 border-[1.5px] border-black rounded-full p-[1px]">
                            <img src={brand?.imageUrl} alt="brandlogo" className="w-full h-full rounded-full" />
                        </div>
                        <div className="">
                            <p className="font-semibold">{brand?.name}</p>
                        </div>
                    </div>
                    <div className="px-5 mt-3">
                        <div className="text-xs tracking-wide">
                            {brand?.description}
                        </div>
                    </div>
                    <div className="flex items-center justify-between px-5 py-3 mt-3 border-t border-b">
                        <div className="text-xs tracking-wide">
                            <Ratingcomponent rating={brand?.rating} />
                        </div>
                        {/* {loggedin === true && <div className="">
                            <button className="bg-navactive text-white px-5 py-[8px] text-xs tracking-wide rounded" onClick={() => subscribe_brand()}>{subscribed === true ? "Subscribe" : "Subcribed"}</button>
                        </div>} */}
                    </div>
                </div>
            </section>
            <section className='p-5'>
                <div className="flex items-center justify-between">
                    <p className="text-xl font-semibold">Top Products</p>
                    <button className="bg-navactive text-white px-5 py-[8px] text-xs tracking-wide rounded" onClick={() => setviewallproducts(!viewallproducts)}>{viewallproducts === true ? "View Less" : "View All"}</button>
                </div>
                {viewallproducts === false ?
                    <div className="grid w-full grid-cols-3 gap-5 pt-5 hidescroll">
                        {product_loading === true ?
                            <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            :
                            <>
                                {products?.slice(0, 6).map((product, index) => (
                                    <ProductCard details={product} key={index} />
                                ))}
                            </>
                        }
                    </div>
                    :
                    <div className="grid w-full grid-cols-3 gap-5 pt-5 hidescroll">
                        {product_loading === true ?
                            <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            :
                            <>
                                {products?.map((product, index) => (
                                    <ProductCard details={product} key={index} />
                                ))}
                                {product_loading !== null ? <ReactVisibilitySensor onChange={onChangeproduct} >
                                    <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                                </ReactVisibilitySensor> : null}
                            </>
                        }
                    </div>
                }
            </section>
            <section className='mt-3'>
                <div className="w-full shadow-sm shadow-black">
                    <a href="https://play.google.com/store/apps/details?id=com.myrevue.app" className="">
                        <img src="https://firebasestorage.googleapis.com/v0/b/revueapp-4eeed.appspot.com/o/site_static_files%2Fbanner%20mobile.png?alt=media&token=192f8565-62eb-4055-9134-dd32f12c7f3c" alt="header banner" className="w-full" />
                    </a>
                </div>
            </section>
            <section className="p-2 pb-20">
                <div className="">
                    <p className="text-xl font-semibold">All Reviews</p>
                </div>
                <div className="grid grid-cols-2 gap-3 mt-3">
                    {loading === true ?
                        <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        :
                        <>
                            {reviews?.map((review, index) => (
                                <Reviewthumbnailcard details={review} key={index} />
                            ))}
                            {loading !== null && <ReactVisibilitySensor onChange={onChange} >
                                <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                            </ReactVisibilitySensor>}
                        </>
                    }
                </div>
            </section>
        </>
    )
}
