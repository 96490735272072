import axios from 'axios';
import { CaretLeft, CircleNotch } from 'phosphor-react';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Downloadapp from '../components/downloadapp';

export default function Hunt() {
    const [loading, setLoading] = useState(true);
    const { id } = useParams();
    const [campaign, setCampaign] = useState({});
    const [subtitle, setSubtitle] = useState("");
    const [showModal, setShowModal] = useState(false);

    const navigate = useNavigate();
    useEffect(() => {
        getHunts()
    }, [])
    async function getHunts() {
        try {
            let headersList = {
                Accept: "*/*",
                Authorization: "asdfwp234-asklf23aehf-zxcl3idjd30-n03j30d3ls-1108sn-BRANDADMIN",
            };

            let reqOptions = {
                url: `https://brandadminapi-zscu3untuq-el.a.run.app/singlehunt/${id}/lszduirtui`,
                method: "GET",
                headers: headersList,
            };

            const response = await axios.request(reqOptions)
            if (response.data.success) {
                const hunt = response.data.data;
                let subtitleText = "";
                if (hunt.rewardCoin > 0) {
                    subtitleText = (Math.floor(hunt.rewardCoin / 50)).toString() + " (Paid)";
                    if (hunt.giveway.toString().trim() !== "") {
                        subtitleText += " + " + hunt.giveway;
                    }
                } else {
                    subtitleText = hunt.giveway;
                }
                setSubtitle(subtitleText)
                setCampaign(hunt)
                setLoading(false);
                return
            }
            return navigate("/hunts")
        } catch (error) {
            console.log(error.message)
        }
    }
    const buttonClassName = "px-2 py-[6px] text-[12px] text-white rounded-md shadow-md bg-navactive shadow-black/40"
    return (
        <div className='w-full h-full overflow-y-auto bg-white'>
            {loading ?
                <div className='flex items-center justify-center w-full h-full'>
                    <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                </div> :
                <div className='w-full'>

                    <div className="absolute z-10 left-5 top-5">
                        <div className="w-8 h-8" onClick={() => navigate("/hunts")}>
                            <CaretLeft size={30} color='white' weight='bold' />
                        </div>
                    </div>
                    <div className="w-full  h-[380px]">
                        <img src={campaign?.productImage} alt="" className="object-cover w-full h-full" />
                    </div>
                    <div className="flex items-center justify-end w-full px-3 -mt-10">
                        <div className="h-[80px] aspect-square rounded-full border-2 border-navactive">
                            <img src={campaign?.brand?.imageUrl} alt="" className="w-full h-full rounded-full" />
                        </div>
                    </div>
                    <div className="px-3 mt-3">
                        <p className="font-semibold">{campaign?.title}</p>
                        <div className="flex flex-wrap gap-2 mt-3">
                            <button className={buttonClassName}>
                                {campaign?.videoType}
                            </button>
                            <button className={buttonClassName}>
                                {campaign?.videoQuality}
                            </button>
                            <button className={buttonClassName}>
                                {campaign?.productDetails?.productType}
                            </button>
                            <button className={buttonClassName}>
                                Preferred Gender: {campaign?.creatorCriteria?.gender}
                            </button>
                            <button className={buttonClassName}>
                                Max Duration: {campaign?.videoDuration}s
                            </button>
                            {campaign?.creatorCriteria?.language?.map((lang, index) => (
                                <button className={buttonClassName} key={index}>
                                    {lang}
                                </button>
                            ))}
                            {campaign?.creatorCriteria?.age?.map((age, index) => (
                                <button className={buttonClassName} key={index}>
                                    {age}
                                </button>
                            ))}
                        </div>
                        <div className="w-full p-3 mt-5 rounded-md bg-navactive/10">
                            <p className="text-sm">Reward</p>
                            <p className="pt-1 font-semibold leading-tight text-navactive">{`₹ ${subtitle}`}</p>
                        </div>
                        <div className="mt-3">
                            <p className="pb-2 text-sm font-bold">Video Shooting Instructions</p>
                            {campaign?.notes?.map((note, index) => (
                                <p className="pt-1 text-sm" key={index}>* {note}</p>
                            ))}
                        </div>
                        <div className="mt-3">
                            <p className="pb-2 text-sm font-bold">Script Idea</p>
                            <p className="pt-1 text-sm">{campaign?.productDetails?.script}</p>
                        </div>
                        {showModal &&
                            <div className=" fixed top-0 left-0 sm:-left-5 z-[100] flex h-full w-full items-end select-none">
                                <div
                                    className="absolute w-full h-full bg-black cursor-pointer bg-opacity-80"
                                    onClick={() => setShowModal(false)}
                                >
                                </div>
                                <div className="relative z-50 w-full p-3">
                                    <Downloadapp close={setShowModal} />
                                </div>
                            </div>
                        }
                        <div className="pb-5 mt-3">
                            <button onClick={() => setShowModal(true)} className="w-full h-10 text-sm text-center text-white rounded-md bg-navactive">Apply Now </button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}
