import axios from 'axios';
import moment from 'moment/moment';
import { ArrowLeft } from 'phosphor-react'
import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

export default function Notifications() {
    const navigate = useNavigate();
    const [notifications, setnotifications] = useState([]);
    const [user, setuser] = useState(JSON.parse(sessionStorage.getItem('user')))
    const regexForName = /^You (followed|following|stopped following) (.+)$/;
    useEffect(() => {
        if (user) {
            async function getNotifications() {
                let headersList = {
                    "Accept": "*/*",
                    "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
                }

                let reqOptions = {
                    url: `https://userapi-zscu3untuq-el.a.run.app/getNotifications/${user?.id}/0`,
                    method: "GET",
                    headers: headersList,
                }

                await axios.request(reqOptions).then(res => {
                    setnotifications(res?.data?.data)
                }
                ).catch(err => console.log(err));
            }
            getNotifications()
        }
        else {
            navigate(-1)
        }
    }, []);

    return (
        <>
            <header className='flex items-center justify-between h-14 bg-white px-5 shadow-md'>
                <div className="h-9 w-9 " onClick={() => navigate(-1)}>
                    <ArrowLeft size={32} color="black" />
                </div>
                <div className="text-black">Notifications</div>
                <div className=""></div>
            </header>
            <section className='px-5'>
                {notifications?.map((data, index) => (
                    <div className="w-full flex items-center justify-between my-3" onClick={() => !data?.goto ? null : navigate(`/${(data?.goto.includes("user") && "profile") || (data?.goto.includes("pro") && "product") || (data?.goto.includes("brand") && "brand")}/${data?.message.match(regexForName)?.[2].trim().toLowerCase().replaceAll(" ", "-").replaceAll("&", "and").replaceAll(",", "_")}/${data.goto}`)} key={index}>
                        <div className="flex items-center w-full  flex-1">
                            <img src={data?.image} alt="" className="h-12 w-12 rounded-full border" />
                            <p className="pl-5 text-sm tracking-wide">{data?.message}</p>
                        </div>
                        <div className="text-[#727272] text-sm">
                            {moment(data?.date).startOf('hour').fromNow(true)}
                        </div>
                    </div>
                ))}
            </section>
        </>
    )
}
