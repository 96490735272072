import React from 'react'
import { useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor'

export default function Storyvideo({ details }) {
    const [visibility, setvisibility] = useState(false);
    const vidRef = useRef();
    const [progress, setProgress] = useState(0);
    const router = useNavigate()
    function onChange(isVisible) {

        if (isVisible) {
            setvisibility(true);
            vidRef.current.play();
            vidRef.current.muted = false;
            router(`/stories/${details?._id}`, { replace: true }); // REPLACE, redirect
        }
        else {
            setvisibility(false)
            vidRef.current.pause();
        }
    }
    const handleOnTimeUpdate = () => {
        const progress = (vidRef.current.currentTime / vidRef.current.duration) * 100;
        // console.log(Math.trunc(progress))
        setProgress(Math.trunc(progress));

    };

    return (
        <ReactVisibilitySensor onChange={onChange} >
            <div className={`h-full w-full snap-start relative bg-black`} id={details?._id}>
                <progress value={progress} max={100} id="progress_review" className="absolute z-40 bottom-0 w-full"></progress>
                <video src={details?.videoUrl}
                    className='h-full w-full object-cover'
                    onTimeUpdate={handleOnTimeUpdate}
                    poster={details.thumbnail}
                    ref={vidRef}
                    playsInline
                    loop
                    muted
                    onClick={(e) => {
                        e.currentTarget.paused ? e.currentTarget.play() : e.currentTarget.pause()
                    }}
                ></video>
            </div>
        </ReactVisibilitySensor>
    )
}
