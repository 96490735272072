import { async } from '@firebase/util';
import axios from 'axios';
import { collection, doc, getDoc, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore/lite';
import { CircleNotch, CodeSimple } from 'phosphor-react';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import ReactVisibilitySensor from 'react-visibility-sensor';
import Reviewvideo from '../components/reviewvideo';
import { db } from '../firebase';

export default function Viewreview() {
    const [reviews, setreviews] = useState([]);
    const { type, id } = useParams();
    const [catId, setcatId] = useState(null);
    const [page, setpage] = useState(0);
    const [loading, setloading] = useState(true);
    const [tag, settag] = useState('')
    useEffect(() => {
        document.getElementById("reviews").scrollTo(0, 38);

        async function getreview() {
            let headersList = {
                "Accept": "*/*",
                "Authorization": type === 'review' ? "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS" : "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }

            let flickReqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksById/${id}`,
                method: "GET",
                headers: headersList,
            }
            let reqOptions = {
                url: `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsById/${id}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(type === "review" ? reqOptions : flickReqOptions).then((res) => {
                const updatedData = { ...res.data.data, type: type };
                setloading(false)
                setreviews([updatedData]);
                getProduct(res?.data?.data?.product?.id)
                settag(updatedData?.flickTag?.replaceAll(" ", "-"))
            }).catch(err => console.log(err))
        }
        async function getProduct(prodid) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
            }

            let reqOptions = {
                url: `https://productapi-zscu3untuq-el.a.run.app/getProduct/${prodid}`,
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                setcatId(res?.data?.product?.catId)
            }).catch((err) => console.log(err))
        }
        getreview();
    }, []);

    useEffect(() => {
        if (catId !== null) {
            fetmorereviews()
        }

    }, [catId])
    useEffect(() => {
        if (tag !== "") {
            fetmorereviews()
        }

    }, [tag])

    async function fetmorereviews() {
        const prevRoute = sessionStorage.getItem("previousRoute");
        const sortKind = sessionStorage.getItem("reviewsSortKind");
        const parts = prevRoute?.split('/');
        const secondPart = parts[parts.length - 2];
        const lastPart = parts?.pop();


        if (!lastPart) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": type === 'review' ? "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS" : "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }
            let flicksreqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksByTag/${tag}/${page}`,
                method: "GET",
                headers: headersList,
            }
            let reqOptions = {
                url: `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsByCategory/${catId}/${page}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(type === "review" ? reqOptions : flicksreqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = rev?.flickTag ? 'flicks' : 'review'
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err))
            return
        }
        else if (lastPart.includes("brand")) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "KLAHSDJHOWR-a234u20jf-23049usldfs-20klafdg1g"
            }

            let reqOptions = {
                url: `https://brandapi-zscu3untuq-el.a.run.app/getReviewsByBrand/${lastPart}/${page}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = 'review'
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err))
            return
        }
        else if (lastPart.includes("pro")) {

            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234!#%)-adsfalsd-a2642342340923424-AL234324zxczD"
            }

            let reqOptions = {
                url: `https://productapi-zscu3untuq-el.a.run.app/getReviewsByProduct/${lastPart}/${page}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                if (sortKind === "topreviews") {
                    const sorted = array.sort((a, b) => a.views > b.views);
                    const newarray = sorted.slice(0, 9);
                    newarray.forEach((rev, index) => {
                        rev.type = 'review'
                    })
                    setreviews([...reviews, ...newarray])
                    setloading(null);
                    return
                }
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = 'review'
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err))
            return
        }
        else if (lastPart.includes("user")) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-adsfalsd-a26463213234234-ALKHFLSFSASDASDASD"
            }

            let reqOptions = {
                url: type === "review" ? `https://userapi-zscu3untuq-el.a.run.app/getUserReviews/${lastPart}/${page}` : `https://userapi-zscu3untuq-el.a.run.app/getUserFlicks/${lastPart}/${page}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = 'flicks'
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err))
            return
        }
        else if (lastPart.includes("reviews")) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdlfha-29347239-ASDHPQPINMV-131241234-adfasdfm-QWOQWIEUALS"
            }

            let reqOptions = {
                url: `https://reviewapi-zscu3untuq-el.a.run.app/getReviewsByCategory/${catId}/${page}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(reqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = 'review'
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err))
            return
        }
        if (secondPart.includes('flicksbytag')) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }
            let flicksreqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/getFlicksByTag/${lastPart}/${page}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(flicksreqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = 'flicks';
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err));
            return
        }
        else if (secondPart.includes('flickssearch')) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "019271-ALSDFWOER-afasldfwoerew-79adf1a3df-7465AASDsdf"
            }
            let flicksreqOptions = {
                url: `https://flicksapi-zscu3untuq-el.a.run.app/searchFlicks/${lastPart}`,
                method: "GET",
                headers: headersList,
            }
            await axios.request(flicksreqOptions).then((res) => {
                if (res?.data?.data.length === 0) {
                    return setloading(null)
                }
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                for (let i = array.length - 1; i > 0; i--) {
                    const j = Math.floor(Math.random() * (i + 1));
                    [array[i], array[j]] = [array[j], array[i]];
                }
                array.forEach((rev, index) => {
                    rev.type = 'flicks';
                })
                setreviews([...reviews, ...array])
                setpage(page => page + 1)
                setloading(false)
            }).catch(err => console.log(err))
            return
        }
        if (prevRoute.includes("/search")) {
            let headersList = {
                "Accept": "*/*",
                "Authorization": "asdfwp234-0835234-34535FHD568-a26463213234234-llksdfsdlfwSLDF"
            }

            let reqOptions = {
                url: "https://userspecificapi-zscu3untuq-el.a.run.app/getStory/user",
                method: "GET",
                headers: headersList,
            }

            await axios.request(reqOptions).then((res) => {
                var array = [];
                res?.data?.data.forEach((review, index) => {
                    if (review?.id !== id) {
                        array.push(review)
                    }
                })
                // for (let i = array.length - 1; i > 0; i--) {
                //     const j = Math.floor(Math.random() * (i + 1));
                //     [array[i], array[j]] = [array[j], array[i]];
                // }
                array.forEach((rev, index) => {
                    rev.type = 'flicks';
                })
                setreviews([...reviews, ...array])
                setloading(null)
                setpage(page => page + 1)
            }).catch(err => console.log(err));
            return
        }

    }

    async function onChange(isVisible) {
        if (isVisible) {
            fetmorereviews()
        }
    }




    return (
        <>
            <section className={`h-full w-full snap-y snap-mandatory overflow-y-scroll hidescroll `} id="reviews">
                {loading === true ?
                    <div className="flex items-center justify-center">   <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' /></div>
                    :
                    <>
                        {reviews?.map((review, index) => (
                            <Reviewvideo details={review} key={index} />
                        ))}
                        {loading !== null && <ReactVisibilitySensor onChange={onChange} >
                            <CircleNotch weight='bold' size={40} color="#6E41E2" className='animate-spin' />
                        </ReactVisibilitySensor>}
                    </>
                }
            </section>
        </>

    )
}
