import React from 'react'
import { Star, StarHalf } from "phosphor-react"
export default function HalfStarRating({ rating, sizeh, colorcode }) {

    return (
        <div>
            {((rating >= 0) && (rating < 1)) ? <div className="flex gap-x-1">
                {((rating > 0) && (rating < 0.5)) ?
                    <StarHalf size={sizeh} weight="fill" color={colorcode} />
                    :
                    <Star size={sizeh} color={colorcode} />
                }
                <Star size={sizeh} color={colorcode} />
                <Star size={sizeh} color={colorcode} />
                <Star size={sizeh} color={colorcode} />
                <Star size={sizeh} color={colorcode} />
            </div> : null}
            {((rating >= 1) && (rating < 2)) ? <div className="flex gap-x-1">
                <Star size={sizeh} weight="fill" color={colorcode} />
                {((rating > 1) && (rating < 1.5)) ?
                    <StarHalf size={sizeh} weight="fill" color={colorcode} />
                    :
                    <Star size={sizeh} color={colorcode} />
                }
                <Star size={sizeh} color={colorcode} />
                <Star size={sizeh} color={colorcode} />
                <Star size={sizeh} color={colorcode} />
            </div> : null}
            {((rating >= 2) && (rating < 3)) ? <div className="flex gap-x-1">
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                {((rating > 2) && (rating < 2.5)) ?
                    <StarHalf size={sizeh} weight="fill" color={colorcode} />
                    :
                    <Star size={sizeh} color={colorcode} />
                }
                <Star size={sizeh} color={colorcode} />
                <Star size={sizeh} color={colorcode} />
            </div> : null}
            {((rating >= 3) && (rating < 4)) ? <div className="flex gap-x-1">
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                {((rating > 3) && (rating < 3.5)) ?
                    <StarHalf size={sizeh} weight="fill" color={colorcode} />
                    :
                    <Star size={sizeh} color={colorcode} />
                }
                <Star size={sizeh} color={colorcode} />
            </div> : null}
            {((rating >= 4) && (rating < 5)) ? <div className="flex gap-x-1">
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                {((rating > 4) && (rating < 4.5)) ?
                    <StarHalf size={sizeh} weight="fill" color={colorcode} />
                    :
                    <Star size={sizeh} color={colorcode} />
                }
            </div> : null}
            {((rating >= 4.5)) ? <div className="flex gap-x-1">
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
                <Star size={sizeh} weight="fill" color={colorcode} />
            </div> : null}
        </div>
    )
}